import React from 'react'
import withNavigate from '../withNavigate'
import comingSoonImg from "../../../icons/coming-soon.avif"

const RptMembershipCancelled = (props) => {
  return (
    <div className="l-base-layout__content">
      <h1>Admin / Reports / Membership cancellations</h1>
      <div className="c-hr"></div>
      <div className="coming-soon-img-section">
        <img src={comingSoonImg} alt="Coming-Soon" width={550} />
      </div>
    </div>
  )
}

export default withNavigate(RptMembershipCancelled)