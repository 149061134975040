import React from "react";
import TableIcon from "../../../icons/table.svg";
import BookingCancellationIcon from "../../../icons/canceled-calendar.svg";
import deleteIcon from "../../../icons/paper-bin.svg";
import membershipCancellationIcon from "../../../icons/membership-cancellation.svg";
import { toScreen } from "../../modules/Routing";
import withNavigate from "../withNavigate";

const ReportsDashboard = (props) => {
  const handleRptCardClick = (actionPath) => {
    const pageUrl = `/reports/${actionPath}`;

    if (actionPath === "monthly") {
      toScreen(false, props.navigate, pageUrl);
    }
    if (actionPath === "booking-cancellations") {
      toScreen(false, props.navigate, pageUrl);
    }
    if (actionPath === "account-deletions") {
      toScreen(false, props.navigate, pageUrl);
    }
    if (actionPath === "membership-cancellations") {
      toScreen(false, props.navigate, pageUrl);
    }
  };

  return (
    <div className="l-base-layout__content">
      <h1>Admin / Reports</h1>
      <div className="c-hr"></div>

      <div className="rpt-cards-container">
        <div className="rpt-card" onClick={() => handleRptCardClick("monthly")}>
          <img src={TableIcon} alt="icon" />
          <p>Monthly report</p>
        </div>
        <div
          className="rpt-card"
          onClick={() => handleRptCardClick("booking-cancellations")}
        >
          <img src={BookingCancellationIcon} alt="icon" />
          <p>Booking cancellations</p>
        </div>
        <div
          className="rpt-card"
          onClick={() => handleRptCardClick("account-deletions")}
        >
          <img src={deleteIcon} alt="icon" />
          <p>Account deletions</p>
        </div>
        <div
          className="rpt-card"
          onClick={() => handleRptCardClick("membership-cancellations")}
        >
          <img
            src={membershipCancellationIcon}
            alt="Membership Cancellation Icon"
          />
          <p>Membership cancellations</p>
        </div>
      </div>
    </div>
  );
};

export default withNavigate(ReportsDashboard);
