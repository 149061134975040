import React, { useState, useEffect } from "react";
import { getHome } from "../modules/Admin";
import { showSpinner, closeSpinner, modal, alert } from "../modules/Dialogs";
import { sessionExpired } from "../config/GeneralConfig";
import { toScreen } from "../modules/Routing";
import withNavigate from "./withNavigate";
import PropTypes from "prop-types";

const Home = ({ userDetails, navigate, updateState }) => {
	const [jobs, setJobs] = useState({ total: "", quoted: "", quoteless: "" });
	const [clients, setClients] = useState({
		total: "",
		active: "",
		blocked: "",
	});
	const [mavs, setMavs] = useState({ total: "", pending: "", blocked: "" });
	const [messages, setMessages] = useState({
		total: 0,
		readStandard: 0,
		readDisputes: 0,
		unreadStandard: 0,
		unreadDisputes: 0,
	});
	const [lastLogin, setLastLogin] = useState("");

	const fetchHomeData = (event) => {
		if (event) event.preventDefault();
		showSpinner();

		const theUser = {
			accountInfo: {
				username: userDetails.accountInfo?.username,
				email: userDetails.accountInfo?.email,
			},
		};

		getHome(theUser, handleHomeResponse, userDetails.jwttoken);
	};

	const handleHomeResponse = (resStatus, responseString, resJson) => {
		closeSpinner();

		if (resStatus === 401) {
			modal(sessionExpired);
			toScreen(false, navigate, "/logout");
		} else if (resStatus === 400) {
			alert(responseString);
		} else if (resStatus === 200) {
			setJobs(resJson?.jobs);
			setClients(resJson?.clients);
			setMavs(resJson?.mavs);
			setMessages(resJson?.messages);
			setLastLogin(resJson?.lastLogin);
		}
	};

	useEffect(() => {
		fetchHomeData();
		return () => {
			const updatedUserDetails = {
				...userDetails,
				messages: messages,
			};
			updateState("userDetails", updatedUserDetails);
		};
	}, []);


	const handleViewMessages = (e, filterType) => {
		const updatedHistoryState = {
			...window.history.state.usr?.selectedFilter || {},
			usr: {
				...window.history.state.usr?.selectedFilter.usr,
				selectedFilter: filterType, // Change this to the new filter value
			},
		};
		toScreen(e, navigate, "/messages");
		window.history.replaceState(updatedHistoryState, "");
	}


	return (
		<div className="l-base-layout__content">
			<h1>Admin / Home</h1>
			<div className="c-hr"></div>

			<div className="l-row">
				<div className="l-col-33">
					<div
						className="c-info-block"
						onClick={(e) => toScreen(e, navigate, "/jobs")}
					>
						Open Jobs:
						<div className="c-info-block__large">{jobs?.total}</div>
						Quoted: {jobs?.quoted} <br />
						Not quoted: {jobs?.quoteless}
					</div>
				</div>
				<div className="l-col-33">
					<div
						className="c-info-block c-info-block--green"
						onClick={(e) => toScreen(e, navigate, "/clients")}
					>
						Clients:
						<div className="c-info-block__large">{clients?.total}</div>
						Active: {clients?.active} <br />
						Blocked: {clients?.blocked}
					</div>
				</div>
				<div className="l-col-33">
					<div
						className="c-info-block c-info-block--teal"
						onClick={(e) => toScreen(e, navigate, "/mavs")}
					>
						MAVs:
						<div className="c-info-block__large">{mavs?.total}</div>
						Pending: {mavs?.pending} <br />
						Blocked: {mavs?.blocked}
					</div>
				</div>
			</div>

			<div className="c-hr"></div>
			<div className="l-row l-row--no-vertical-padding">
				<div className="l-col-33">
					<h2>Tasks</h2>
				</div>
				<div className="l-col-66">
					<div className="c-list-entry c-list-entry--large-text">
						<div className="c-list-entry__primary">
							Unread Messages:{" "}
							<span className="u-color-2 u-font-semi-bold">
								{messages?.unreadStandard}
							</span>
						</div>
						<button
							type="button"
							className="c-btn c-btn--3 c-btn--small"
							onClick={(e) => handleViewMessages(e, "unread")}
						>
							View
						</button>
					</div>

					<div className="c-list-entry c-list-entry--large-text">
						<div className="c-list-entry__primary">
							Unread Disputes:{" "}
							<span className="u-color-4 u-font-semi-bold">
								{messages?.unreadDisputes}
							</span>
						</div>
						<button
							type="button"
							className="c-btn c-btn--3 c-btn--small"
							onClick={(e) => handleViewMessages(e, "disputes")}
						>
							View
						</button>
					</div>
				</div>
			</div>

			<div className="c-hr"></div>

			<div className="u-clearfix">
				<p className="u-float-right">
					Last login: <b>{lastLogin}</b>
				</p>
			</div>
		</div>
	);
}


Home.propTypes = {
	userDetails: PropTypes.object.isRequired,
	navigate: PropTypes.func.isRequired,
	updateState: PropTypes.func.isRequired,
};

export default withNavigate(Home);
