import React, { useRef } from 'react';
import _ from 'lodash';
import { confirm } from '../modules/Dialogs';
import { longPressTime } from '../config/GeneralConfig';
import parse from 'html-react-parser';
import RichTextInput from './RichTextInput';
import { dateProcess, timeStamp } from '../modules/Generic';
import PropTypes from 'prop-types';

const AuditNotes = ({ notes, updateNotes }) => {
    const richTextInputRef = useRef(null);

    const saveText = (event, theText, theIdentifier, theCallback) => {
        if (event) {
            event.preventDefault();
        }

        let tmpNotes = [...notes];
        let newNote;
        let tmpEdits;

        if (theIdentifier !== undefined) {
            tmpEdits = [...notes[theIdentifier].edits];
            tmpEdits.unshift({ standardDate: timeStamp() });

            newNote = {
                content: theText,
                edits: tmpEdits
            };

            tmpNotes[theIdentifier] = newNote;
        } else {
            newNote = {
                content: theText,
                edits: [{
                    standardDate: timeStamp()
                }]
            };

            tmpNotes.push(newNote);
        }

        tmpNotes = _.orderBy(tmpNotes, 'edits[0].standardDate', 'desc');
        updateNotes(tmpNotes, theCallback);
    };

    const editNote = (event, key) => {
        if (event) {
            event.preventDefault();
        }
        richTextInputRef.current.goRichText(event, key, notes[key].content);
    };

    const deleteNote = (event, key) => {
        if (event) {
            event.preventDefault();
        }

        confirm('Delete note?', false, 'Cancel', 'Delete', () => {
            let tmpNotes = [...notes];
            tmpNotes.splice(key, 1);
            updateNotes(tmpNotes);
        });
    };

    const renderNotes = (key) => {
        const deleteNoteStart = (event) => {
            if (event) {
                event.preventDefault();
            }
            window.deleteNoteTimer = setTimeout(() => deleteNote(false, key), longPressTime);
        };

        const deleteNoteCancel = (event) => {
            if (event) {
                event.preventDefault();
            }
            clearTimeout(window.deleteNoteTimer);
        };

        const note = notes[key];

        return (
            <div className="c-audit-note"
                onTouchStart={deleteNoteStart}
                onTouchEnd={deleteNoteCancel}
                onMouseDown={deleteNoteStart}
                onMouseUp={deleteNoteCancel}
                onMouseLeave={deleteNoteCancel}
                onClick={(e) => editNote(e, key)}
                key={key}>
                <div className="c-audit-note__date">
                    <span className="u-before-icon-calendar">&nbsp;&nbsp;{dateProcess(note.edits[0].standardDate, "printForAuditNote")}</span>
                </div>
                {parse(note?.content)}
            </div>
        );
    };

    return (
        <div>
            <div>
                <button
                    className="c-btn c-btn--small c-btn--full-width c-btn--yellow c-btn--black-text u-margin-bottom-0"
                    onClick={(e) => richTextInputRef.current.goRichText(e)}
                >
                    Add Note
                </button>
                <div className="c-hr u-margin-bottom-s u-margin-top-sm"></div>
                <div className="c-audit-note-container">
                    {notes && _.size(notes) > 0 ? Object.keys(notes).map(renderNotes) : null}
                </div>
            </div>

            <RichTextInput ref={richTextInputRef} saveText={saveText} />
        </div>
    );
};

AuditNotes.propTypes = {
    updateNotes: PropTypes.func.isRequired,
    notes: PropTypes.array.isRequired
};

export default AuditNotes;
