import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import {
  getQuotesRecordsCount,
  getretrieveQuotes,
} from "../modules/JobAndQuoteManager";
import { showSpinner, closeSpinner, modal, alert } from "../modules/Dialogs";
import { sessionExpired } from "../config/GeneralConfig";
import { toScreen } from "../modules/Routing";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

const Quotes = ({ userDetails }) => {
  const navigate = useNavigate();
  const jwtToken = userDetails.jwttoken;

  const [searchValue, setSearchValue] = useState(null);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(
    window.history.state?.usr?.selectedFilter ?? "open"
  );
  const [sort, setSort] = useState("");
  const [quotes, setQuotes] = useState([]);
  const [filterCount, setFilterCount] = useState({
    total: 0,
    open: 0,
    closed: 0,
    agreed: 0,
    paid: 0,
    complete: 0,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
  });

  const getQuotes = useCallback(
    (event) => {
      showSpinner();

      const queryParams = {
        search,
        filter,
        sort,
        page: pagination.page,
        limit: 15,
      };

      getretrieveQuotes(queryParams, getQuotes_done, userDetails.jwttoken);
    },
    [search, filter, sort, pagination.page, userDetails.jwttoken]
  );

  const getQuotes_done = (resStatus, responseString, resJson) => {
    closeSpinner();

    if (resStatus === 401) {
      modal(sessionExpired);
      toScreen(false, navigate, "/logout");
    } else if (resStatus === "400") {
      setQuotes([]);
      setPagination((prev) => ({
        ...prev,
        page: 1,
        total: 1,
      }));

      alert(responseString);
    } else if (resStatus === 200) {
      setPagination((prev) => ({
        ...prev,
        total:
          resJson.totalRecords > 0
            ? Math.ceil(resJson.totalRecords / 15)
            : pagination.total,
      }));
      setQuotes(resJson?.data || []);
    }
  };

  const fetchFilterCount = useCallback(() => {
    showSpinner();
    getQuotesRecordsCount(handleFilterCount_done, jwtToken);
  }, [jwtToken]);

  // Handle filter count response
  const handleFilterCount_done = useCallback(
    (resStatus, responseString, resJson) => {
      closeSpinner();

      if (resStatus === 401) {
        modal(sessionExpired);
        toScreen(false, navigate, "/logout");
      } else if (resStatus === "400") {
        alert(responseString);
      } else if (resStatus === 200) {
        setFilterCount({
          total: resJson?.totalDocs,
          open: resJson?.openQuotes,
          closed: resJson?.closedQuotes,
          paid: resJson?.paidQuotes,
          complete: resJson?.completedQuotes,
        });
      }
    },
    [navigate]
  );

  const nextPage = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (pagination.page < pagination.total) {
      setPagination(
        (prev) => ({
          ...prev,
          page: prev.page + 1,
        }),
        getQuotes
      );
    }
  };

  const prevPage = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (pagination.page > 1) {
      setPagination(
        (prev) => ({
          ...prev,
          page: prev.page - 1,
        }),
        getQuotes
      );
    }
  };

  const searchQuote = useCallback(() => {
    setSearch(searchValue);
    setFilter(searchValue === "" ? "open" : null);
    setPagination({ page: 1, total: 1 });
  }, [searchValue]);

  const handleInputChange = useCallback((event) => {
    const { name, value, type, checked } = event.target;

    const inputValue = type === "checkbox" ? checked : value;

    if (name === "search") {
      setSearchValue(inputValue);
    } else if (name === "filter") {
      setSearch("");
      setSearchValue(null);
      setFilter(inputValue);
      setPagination({ page: 1, total: 1 });
    } else if (name === "mode") {
      setMode(inputValue);
    }
  }, []);

  const sortQuotes = useCallback(
    (event, field) => {
      if (event) event.preventDefault();

      const sortFields = {
        name: { asc: "name=1", desc: "name=-1" },
        jobDate: { asc: "jobDate=1", desc: "jobDate=-1" },
        creationDate: { asc: "creationDate=1", desc: "creationDate=-1" },
        location: { asc: "location=1", desc: "location=-1" },
      };

      const currentSort = sortFields[field];
      if (!currentSort) return;

      const newSort =
        sort === currentSort.asc ? currentSort.desc : currentSort.asc;

      setSearch("");
      setSort(newSort);
      setPagination({ page: 1, total: 1 });
    },
    [sort]
  );

  const getSortClass = useCallback(
    (key) => {
      if (sort === `${key}=1`) {
        return "c-list-filter__button c-list-filter__button--icon-up";
      } else if (sort === `${key}=-1`) {
        return "c-list-filter__button c-list-filter__button--icon-down";
      }
      // return "c-list-filter__button c-list-filter__button--faded";
      return "c-list-filter__button--faded cursor-default";

    },
    [sort]
  );

  const renderQuotes = useCallback((quote, key) => (
    <div className="c-list-entry" key={key}>
      <div className="c-list-entry__name">
        <b className="text-capitalize">{quote?.firstname}</b>
      </div>

      <div className="c-list-entry__jobdate">
        <span className="u-before-icon-calendar2">&nbsp;&nbsp;{moment(quote?.jobDate)?.tz("Europe/London")?.format("DD MMM YYYY")}</span> <br />
        <span className="u-before-icon-clock">
          &nbsp;&nbsp;
          {moment(quote?.jobDate)?.tz("Europe/London")?.format("hh:mm ")}
        </span>
      </div>
      <div className="c-list-entry__location">
        <span className="u-before-icon-location text-capitalize">&nbsp;&nbsp;
          {`${quote?.originAddress || ""} ${quote?.originAddress || quote?.destinationAddress ? "→" : ""
            } ${quote?.destinationAddress || ""}`}
        </span>

      </div>
      <div className="c-list-entry__created">
        <span className="u-before-icon-calendar2">&nbsp;&nbsp;{moment(quote?.createdAt)?.tz("Europe/London")?.format("DD MMM YYYY")}</span><br />
        <span className="u-before-icon-clock">&nbsp;&nbsp;{moment(quote?.createdAt)?.tz("Europe/London")?.format("hh:mm ")}</span>
      </div>
      <button
        type="button"
        className="c-btn c-btn--teal c-btn--small"
        onClick={(e) => toScreen(e, navigate, "/quote/" + quote?.shortId)}
      >
        View
      </button>
    </div>
  ));

  useEffect(() => {
    getQuotes();
  }, [filter, sort, pagination.page, getQuotes]);

  useEffect(() => {
    fetchFilterCount();
  }, []);

  return (
    <div className="l-base-layout__content">
      <h1>Admin / Quotes</h1>

      <div className="c-hr"></div>

      <div className="c-subheader-info-panel">
        <div className="c-subheader-info-panel__primary">
          Total: {filterCount.total}
        </div>
        <div className="c-subheader-info-panel__search">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              searchQuote();
            }}
          >
            <input
              type="text"
              value={searchValue || ""}
              onChange={handleInputChange}
              name="search"
              placeholder=""
              className="c-subheader-info-panel__input"
            />
            <button
              type="button"
              className="c-btn-outline c-btn-outline--white c-btn-outline--small"
              onClick={() => searchValue !== null && searchQuote()}
            >
              Search
            </button>
          </form>
        </div>
      </div>

      <div className="c-hr"> </div>
      <div className="l-row">
        <div className="l-col-100 u-padding-top-0 u-padding-bottom-0">
          <div className="filter-bar">
            <div className="c-checkbox-radio-block">
              <input
                type="radio"
                id="open"
                name="filter"
                value="open"
                onChange={handleInputChange}
                checked={filter === "open"}
              />
              <label htmlFor="open">Open ({filterCount?.open})</label>
            </div>
            <div className="c-checkbox-radio-block">
              <input
                type="radio"
                id="paid"
                name="filter"
                value="paid"
                onChange={handleInputChange}
                checked={filter === "paid"}
              />
              <label htmlFor="paid">Paid ({filterCount?.paid})</label>
            </div>
            <div className="c-checkbox-radio-block">
              <input
                type="radio"
                id="complete"
                name="filter"
                value="complete"
                onChange={handleInputChange}
                checked={filter === "complete"}
              />
              <label htmlFor="complete">
                Complete ({filterCount?.complete})
              </label>
            </div>
            <div className="c-checkbox-radio-block">
              <input
                type="radio"
                id="closed"
                name="filter"
                value="closed"
                onChange={handleInputChange}
                checked={filter === "closed"}
              />
              <label htmlFor="closed">Closed ({filterCount?.closed})</label>
            </div>
          </div>
        </div>

        <div className="l-col-100">
          <div className="c-pagination">
            <button
              type="button"
              className="c-pagination__prev"
              onClick={prevPage}
            >
              &lt;&lt; Prev
            </button>
            {pagination?.page} / {pagination?.total}
            <button
              type="button"
              className="c-pagination__next"
              onClick={nextPage}
            >
              Next &gt;&gt;
            </button>
          </div>
          <div className="c-hr u-margin-bottom-0"></div>

          <div>
            <div className="c-list-entry c-list-entry__header">
              <div className="c-list-entry__name c-list-filter">
                <button
                  className={getSortClass("name")}
                // onClick={(e) => sortQuotes(e, "name")}
                >
                  Name
                </button>
              </div>
              <div className="c-list-entry__jobdate c-list-filter">
                <button
                  className={getSortClass("jobDate")}
                // onClick={(e) => sortQuotes(e, "jobDate")}
                >
                  Job date
                </button>
              </div>
              <div className="c-list-entry__location c-list-filter">
                <button
                  className={getSortClass("location")}
                // onClick={(e) => sortQuotes(e, "location")}
                >
                  Location
                </button>
              </div>
              <div className="c-list-entry__created c-list-filter">
                <button
                  className={getSortClass("creationDate")}
                // onClick={(e) => sortQuotes(e, "creationDate")}
                >
                  Created
                </button>
              </div>
            </div>
          </div>

          <div>
            {quotes?.length > 0 ? (
              quotes?.map(renderQuotes)
            ) : (
              <div className="u-text-align-center u-margin-top-bottom-m u-color-red">
                No quotes found
              </div>
            )}
          </div>


          <div className="c-hr u-margin-bottom-0"></div>
          <div className="c-pagination">
            <button
              type="button"
              className="c-pagination__prev"
              onClick={prevPage}
            >
              &lt;&lt; Prev
            </button>
            {pagination.page} / {pagination.total}
            <button
              type="button"
              className="c-pagination__next"
              onClick={nextPage}
            >
              Next &gt;&gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Quotes.propTypes = {
  userDetails: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default Quotes;
