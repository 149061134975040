import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { getUserRecordsCount, getUsersClients } from "../modules/Admin";
import { showSpinner, closeSpinner, modal, alert } from "../modules/Dialogs";
import { sessionExpired } from "../config/GeneralConfig";
import { toScreen } from "../modules/Routing";

const pageSize = 15;

const Clients = ({ userDetails }) => {
  const navigate = useNavigate();
  const jwtToken = userDetails.jwttoken;

  const [searchValue, setSearchValue] = useState(null);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(
    window.history.state?.usr?.selectedFilter ?? "active"
  );
  const [sort, setSort] = useState("");
  const [clients, setClients] = useState([]);
  const [filterCount, setFilterCount] = useState({
    activeUsers: 0,
    preactiveUsers: 0,
    blockedUsers: 0,
    totalDocs: 0,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
  });

  // Fetch clients data
  const getClients = useCallback(
    (event) => {
      if (event) event.preventDefault();

      showSpinner();

      const queryParams = {
        filter,
        search,
        page: pagination.page,
        limit: pageSize,
        sort,
      };

      getUsersClients(queryParams, getClients_done, jwtToken);
    },
    [filter, search, pagination.page, sort, jwtToken]
  );

  // Handle clients data response
  const getClients_done = useCallback(
    (resStatus, responseString, resJson) => {
      closeSpinner();

      if (resStatus === 401) {
        modal(sessionExpired);
        toScreen(false, navigate, "/logout");
      } else if (resStatus === "400") {
        setClients([]);
        setPagination({ page: 1, total: 1 });
        alert(responseString);
      } else if (resStatus === 200) {
        setPagination((prev) => ({
          page: prev.page,
          pageSize: pageSize,
          total:
            (resJson.totalRecords > 0 &&
              Math.ceil(resJson.totalRecords / pageSize)) ||
            prev.total,
        }));
        setClients(resJson?.data || []);
      }
    },
    [navigate]
  );

  // Fetch filter counts
  const fetchFilterCount = useCallback(() => {
    showSpinner();
    getUserRecordsCount({ userType: "user" }, handleFilterCount_done, jwtToken);
  }, [jwtToken]);

  // Handle filter count response
  const handleFilterCount_done = useCallback(
    (resStatus, responseString, resJson) => {
      closeSpinner();

      if (resStatus === 401) {
        modal(sessionExpired);
        toScreen(false, navigate, "/logout");
      } else if (resStatus === "400") {
        alert(responseString);
      } else if (resStatus === 200) {
        setFilterCount({
          activeUsers: resJson?.activeUsers,
          preactiveUsers: resJson?.preactiveUsers,
          blockedUsers: resJson?.blockedUsers,
          totalDocs: resJson?.totalDocs,
        });
      }
    },
    [navigate]
  );

  // Pagination handlers
  const nextPage = useCallback(
    (event) => {
      if (event) event.preventDefault();

      if (pagination.page < pagination.total) {
        setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      }
    },
    [pagination]
  );

  const prevPage = useCallback(
    (event) => {
      if (event) event.preventDefault();

      if (pagination.page > 1) {
        setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
      }
    },
    [pagination]
  );

  // Search handler
  const searchClient = useCallback(() => {
    setSearch(searchValue);
    setFilter(searchValue === "" ? "active" : null);
    setPagination({ page: 1, total: 1 });
  }, [searchValue]);

  // Sort handler
  const sortClients = useCallback(
    (event, field) => {
      if (event) event.preventDefault();

      const sortFields = {
        name: { asc: "name=1", desc: "name=-1" },
        email: { asc: "email=1", desc: "email=-1" },
        tel: { asc: "tel=1", desc: "tel=-1" },
        location: { asc: "location=1", desc: "location=-1" },
        completedJobCount: {
          asc: "completedJobCount=1",
          desc: "completedJobCount=-1",
        },
        totalJobCount: { asc: "totalJobCount=1", desc: "totalJobCount=-1" },
        reviews: { asc: "reviews=1", desc: "reviews=-1" },
      };

      const currentSort = sortFields[field];
      if (!currentSort) return;

      const newSort = sort === currentSort.asc ? currentSort.desc : currentSort.asc;

      setSearch("");
      setSort(newSort);
      setPagination({ page: 1, total: 1 });
    },
    [sort]
  );

  // Input handler
  const handleInputChange = useCallback((event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;

    if (name === "search") {
      setSearchValue(inputValue);
    } else if (name === "filter") {
      setSearch("");
      setSearchValue(null);
      setFilter(inputValue);
      setPagination({ page: 1, total: 1 });
    }
  }, []);

  const getSortClass = useCallback(
    (key) => {
      if (sort === `${key}=1`) {
        return "c-list-filter__button c-list-filter__button--icon-up";
      } else if (sort === `${key}=-1`) {
        return "c-list-filter__button c-list-filter__button--icon-down";
      }
      return "c-list-filter__button c-list-filter__button--faded";
    },
    [sort]
  );

  const renderClients = useCallback(
    (client, key) => (
      <div className="c-list-entry" key={key}>
        <div className="c-list-entry__clientname">
          <b className="text-capitalize">{client?.accountInfo?.firstname}</b>
        </div>
        <div className="c-list-entry__tel">{client.accountInfo?.telephone}</div>
        <div className="c-list-entry__email">
          <a href={`mailto:${client.accountInfo?.email}`}>
            {client.accountInfo?.email}
          </a>
        </div>
        <div className="c-list-entry__clientjobs">
          <b>{client?.totalJobCount ?? 0}</b>
        </div>
        <div className="c-list-entry__clientcompleted">
          <b>{client?.completedJobs ?? 0}</b>
        </div>
        <button
          type="button"
          className="c-btn c-btn--teal c-btn--small"
          onClick={(e) => toScreen(e, navigate, `/client/${client?.shortId}`)}
        >
          View
        </button>
        <button
          type="button"
          className="c-btn c-btn--green c-btn--small"
          onClick={(e) =>
            toScreen(e, navigate, `/message/${client.shortId}`, false, {
              receiverId: client?._id,
              receiverShortId: client?.shortId,
              receiverType: client?.userType,
              userShortId1: "admin",
              userShortId2: client?.shortId,
              username2: client?.accountInfo?.username,
              selectedPage: pagination.page,
              selectedFilter: filter,
              previousPath: window.location.pathname,
            })
          }
        >
          Contact Client
        </button>
      </div>
    ),
    [filter, pagination.page, navigate]
  );

  // Sort button component
  const SortButton = useCallback(
    ({ field, label }) => (
      <button
        className={getSortClass(field)}
        onClick={(e) => sortClients(e, field)}
      >
        {label}
      </button>
    ),
    [getSortClass, sortClients]
  );

  // Effect to fetch data when pagination/filter/sort changes
  useEffect(() => {
    getClients();
  }, [pagination.page, filter, sort, search]);

  // Initial data fetch
  useEffect(() => {
    fetchFilterCount();
  }, []);

  // Create table header once
  const tableHeader = useMemo(
    () => (
      <div className="c-list-entry c-list-entry__header">
        <div className="c-list-entry__clientname c-list-filter">
          <SortButton field="name" label="Name" />
        </div>
        <div className="c-list-entry__tel c-list-filter">
          <SortButton field="tel" label="Tel" />
        </div>
        <div className="c-list-entry__email c-list-filter">
          <SortButton field="email" label="Email" />
        </div>
        <div className="c-list-entry__clientjobs c-list-filter">
          <SortButton field="totalJobCount" label="Jobs" />
        </div>
        <div className="c-list-entry__clientcompleted c-list-filter">
          <SortButton field="completedJobCount" label="Completed" />
        </div>
      </div>
    ),
    [SortButton]
  );

  // Create pagination component
  const PaginationControls = useMemo(
    () => (
      <div className="c-pagination">
        <button type="button" className="c-pagination__prev" onClick={prevPage}>
          &lt;&lt; Prev
        </button>
        {pagination.page} / {pagination.total}
        <button type="button" className="c-pagination__next" onClick={nextPage}>
          Next &gt;&gt;
        </button>
      </div>
    ),
    [pagination.page, pagination.total, prevPage, nextPage]
  );

  return (
    <div className="l-base-layout__content">
      <h1>Admin / Clients</h1>
      <div className="c-hr"></div>

      <div className="c-subheader-info-panel">
        <div className="c-subheader-info-panel__primary">
          Total: {filterCount?.totalDocs}
        </div>
        <div className="c-subheader-info-panel__search">
          <form>
            <input
              type="text"
              value={searchValue || ""}
              onChange={handleInputChange}
              name="search"
              placeholder=""
              className="c-subheader-info-panel__input"
            />
            <button
              type="button"
              className="c-btn-outline c-btn-outline--white c-btn-outline--small"
              onClick={() => searchValue !== null && searchClient()}
              disabled={searchValue === null}
            >
              Search
            </button>
          </form>
        </div>
      </div>

      <div className="c-hr"></div>

      <div className="l-row">
        <div className="l-col-100 u-padding-top-0 u-padding-bottom-0">
          <div className="filter-bar">
            {[
              // { id: "preactivation", label: "Pre Activation", count: filterCount?.preactiveUsers },
              {
                id: "active",
                label: "Active",
                count: filterCount?.activeUsers,
              },
              {
                id: "blocked",
                label: "Blocked",
                count: filterCount?.blockedUsers,
              },
            ]?.map(({ id, label, count }) => (
              <div className="c-checkbox-radio-block" key={id}>
                <input
                  type="radio"
                  id={id}
                  name="filter"
                  value={id}
                  onChange={handleInputChange}
                  checked={filter === id}
                />
                <label htmlFor={id}>
                  {label} ({count})
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="l-col-100">
          {PaginationControls}
          <div className="c-hr u-margin-bottom-0"></div>

          <div>{tableHeader}</div>

          <div>
            {clients?.length > 0 ? (
              clients?.map(renderClients)
            ) : (
              <div className="u-text-align-center u-margin-top-bottom-m u-color-red">
                No clients found
              </div>
            )}
          </div>

          <div className="c-hr u-margin-bottom-0 u-clear-both"></div>
          {PaginationControls}
        </div>
      </div>
    </div>
  );
};

Clients.propTypes = {
  userDetails: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default Clients;
