import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import cogIcon from "../../icons/cog.svg";
import manIcon from "../../icons/man.svg";
import vanIcon from "../../icons/van.svg";
import calanderIcon from "../../icons/calendar.svg";
import listIcon from "../../icons/list.svg";
import envelopIcon from "../../icons/envelope.svg";
import smartPhoneIcon from "../../icons/smartphone.svg";
import lockIcon from "../../icons/lock.svg";
import lineChartIcon from "../../icons/line-chart.svg";

const Navigation = ({ userDetails }) => {
  const navigate = useNavigate();
  return (
    <div className="l-base-layout__menu">
      {userDetails.jwttoken != null &&
      userDetails.accountInfo.username != null ? (
        <div className="c-navigation">
          <NavLink
            className={({ isActive }) =>
              isActive || window.location.pathname === "/"
                ? "c-navigation__item active"
                : "c-navigation__item"
            }
            activeclassname="is-active"
            exact="true"
            to="/"
          >
            <img
              className={window.location.pathname === "/" && "img_invert"}
              src={cogIcon}
              alt=""
            />
            Home
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive || window.location.pathname.startsWith("/client")
                ? "c-navigation__item active"
                : "c-navigation__item"
            }
            activeclassname="is-active"
            to="/clients"
          >
            <img
              className={
                window.location.pathname.startsWith("/client") && "img_invert"
              }
              src={manIcon}
              alt=""
            />
            Clients
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive || window.location.pathname.startsWith("/mav")
                ? "c-navigation__item active"
                : "c-navigation__item"
            }
            activeclassname="is-active"
            to="/mavs"
          >
            <img
              className={
                window.location.pathname.startsWith("/mav") && "img_invert"
              }
              src={vanIcon}
              alt=""
            />
            MAVs
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive || window.location.pathname.startsWith("/job")
                ? "c-navigation__item active"
                : "c-navigation__item"
            }
            activeclassname="is-active"
            to="/jobs"
          >
            <img
              className={
                window.location.pathname.startsWith("/job") && "img_invert"
              }
              src={calanderIcon}
              alt=""
            />
            Jobs
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive || window.location.pathname.startsWith("/quote")
                ? "c-navigation__item active"
                : "c-navigation__item"
            }
            activeclassname="is-active"
            to="/quotes"
          >
            <img
              className={
                window.location.pathname.startsWith("/quote") && "img_invert"
              }
              src={listIcon}
              alt=""
            />
            Quotes
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive || window.location.pathname.startsWith("/message")
                ? "c-navigation__item active"
                : "c-navigation__item"
            }
            activeclassname="is-active"
            to="/messages"
          >
            <img
              className={
                window.location.pathname.startsWith("/message") && "img_invert"
              }
              src={envelopIcon}
              alt=""
            />
            Messages
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive || window.location.pathname.startsWith("/report")
                ? "c-navigation__item active"
                : "c-navigation__item"
            }
            activeclassname="is-active"
            to="/reports"
          >
            <img
              className={
                window.location.pathname.startsWith("/report") && "img_invert"
              }
              src={lineChartIcon}
              alt=""
            />
            Reports
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive || window.location.pathname.startsWith("/push")
                ? "c-navigation__item active"
                : "c-navigation__item"
            }
            activeclassname="is-active"
            to="/pushNotifications"
          >
            <img
              className={
                window.location.pathname.startsWith("/push") && "img_invert"
              }
              src={smartPhoneIcon}
              alt=""
            />
            Push
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive || window.location.pathname.startsWith("/logout")
                ? "c-navigation__item active"
                : "c-navigation__item"
            }
            activeclassname="is-active"
            to="/logout"
          >
            <img
              className={
                window.location.pathname.startsWith("/logout") && "img_invert"
              }
              src={lockIcon}
              alt=""
            />
            Log Out
          </NavLink>
        </div>
      ) : (
        <div className="c-navigation">
          <NavLink
            className={({ isActive }) =>
              isActive || window.location.pathname.startsWith("/signin")
                ? "c-navigation__item active"
                : "c-navigation__item"
            }
            to="/signin"
          >
            <img
              className={
                window.location.pathname.startsWith("/signin") && "img_invert"
              }
              src={lockIcon}
              alt=""
            />
            Sign In
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default Navigation;
