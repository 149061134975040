import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { setQuote } from "../modules/Admin";
import {
  showSpinner,
  closeSpinner,
  modal,
  confirm,
  updateApp,
  alert,
} from "../modules/Dialogs";
import {
  appVersionOutdatedExpired,
  userIsBlocked,
  sessionExpired,
  serverTimeOutMsg,
  serverUrl,
  userImagesFolder,
  imageFormat,
  defaultAvatarImage,
} from "../config/GeneralConfig";
import { spoofedISODateProcess } from "../modules/Generic";
import { toScreen } from "../modules/Routing";
import { retrieveRatingsMav } from "../modules/Ratings";
import EditRating from "./EditRating";
import withNavigate from "./withNavigate";

const Reviews = ({
  userDetails,
  mavId,
  updateState,
  navigate
}) => {
  const extraAttributes = window.history.state.usr;
  const editRatingRef = useRef(null);

  // Individual state variables
  const [sort, setSort] = useState('{"job.creationDate":1}');
  const [mavDetails, setMavDetails] = useState({
    _id: "",
    short_id: "",
    notes: [],
    accountInfo: {
      username: "",
      drivingLicence: "",
      companyName: "",
      vatNumber: "",
      wasteHandlingLicence: "",
      publicLiabilityInsurance: false,
      goodsInTransitInsurance: false,
      generalVanInsurance: false,
      firstname: "",
      surname: "",
      telephone: "",
      email: "",
      avatar: "",
      terms: false,
      settings: {
        emailnotifications: false,
        holidaymode: false,
        marketingnotifications: false,
        pushnotifications: false,
      },
      baseLocation: {
        homeaddress: {
          googleValidated: "",
          placeId: "",
          formattedAddress: "",
        },
        radius: 50,
      },
    },
    earlyAdopter: "",
    stripeAccountId: "",
    userType: "",
    status: "",
    quotes: {},
    totalRatings: 0,
    ratingAsMav: 0
  });
  const [ratings, setRatings] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const editRating = (event, theKey) => {
    if (event) {
      event.preventDefault();
    }

    editRatingRef.current.goEditRating(
      event,
      theKey,
      ratings[theKey].comment,
      ratings[theKey].stars,
      ratings[theKey].job._creator.accountInfo.firstname
    );
  };

  const saveRating = (event, theKey, theComment, theStars) => {
    if (event) {
      event.preventDefault();
    }

    showSpinner();

    const theQuote = {
      short_id: ratings[theKey].job.quote.short_id,
      ratings: [
        {
          stars: parseInt(theStars),
          comment: theComment,
        },
      ],
    };

    setQuote(
      theQuote,
      (resStatus, responseString, resJson) => {
        closeSpinner();

        if (resStatus == 401) {
          modal(sessionExpired);
          toScreen(false, navigate, "/logout");
        } else if (resStatus == "400") {
          modal(responseString);
        } else if (resStatus == 200) {
          getMavRatings();
        }
      },
      userDetails.jwttoken
    );
  };

  const deleteRating = (event, theKey) => {
    if (event) {
      event.preventDefault();
    }

    confirm(
      false,
      "Are you sure you want to delete this review?",
      "Cancel",
      "Delete",

      () => {
        saveRating(event, theKey, false, false);
      }
    );
  };

  const getMavRatings = () => {
    if (
      userDetails.jwttoken != null &&
      userDetails.accountInfo.username != null
    ) {
      const theMav = {
        accountInfo: {
          username: userDetails.accountInfo.username,
          email: userDetails.accountInfo.email,
        },
        short_id: mavId ?? window.location.pathname?.split("/")[2],
        contentmode: "full",
        profilemode: "full",
        sort: sort,
      };

      if (currentPage && currentPage != "") {
        theMav.page = currentPage;
      }

      return retrieveRatingsMav(
        theMav,
        getMavRatings_done,
        userDetails.jwttoken
      );
    }
  };

  const getMavRatings_done = (resStatus, responseString, resJson) => {
    if (resStatus == 401) {
      if (responseString == "ERROR: OUTDATED VERSION") {
        var updateUrl;

        if (
          window.appMode == "mobile" &&
          device &&
          (device.platform == "iOS" || device.version == "iPhone")
        ) {
          updateUrl =
            "https://itunes.apple.com/us/app/man-van/id1455174908?ls=1&mt=8";
        } else if (
          window.appMode == "mobile" &&
          device &&
          (device.platform == "Android" || device.version == "Android")
        ) {
          updateUrl =
            "https://play.google.com/store/apps/details?id=uk.co.makeonline.manandvan";
        } else {
          updateUrl = "https://www.manandvan.app/";
        }

        updateApp(appVersionOutdatedExpired, false, () => {
          window.open(updateUrl, "_system");
        });
      } else if (responseString == "ERROR: USER BLOCKED") {
        modal(userIsBlocked);
        toScreen(false, navigate, "/logout");
      } else {
        modal(sessionExpired);
        toScreen(false, navigate, "/logout");
      }
    } else if (
      resStatus == "504" ||
      resStatus == 504 ||
      resStatus == "502" ||
      resStatus == 502
    ) {
      modal(serverTimeOutMsg);
      toScreen(false, navigate, "/welcome");
    } else if (resStatus == "400" || resStatus == 400) {
      alert(responseString);
    } else if (resStatus == 200) {
      setMavDetails(resJson._creator);
      setRatings(resJson._creator.ratings);
      setTotalPages(resJson.pagination.total);
    }
  };

  const sortRatings = (event, theSort) => {
    if (event) {
      event.preventDefault();
    }

    let tmpSort;

    if (theSort == "date") {
      tmpSort = sort === '{"job.creationDate":1}'
        ? '{"job.creationDate":-1}'
        : '{"job.creationDate":1}';
    } else if (theSort == "stars") {
      tmpSort = sort === '{"ratings[0].stars":1}'
        ? '{"ratings[0].stars":-1}'
        : '{"ratings[0].stars":1}';
    } else if (theSort == "client") {
      tmpSort = sort === '{"job._creator.accountInfo.firstname":1}'
        ? '{"job._creator.accountInfo.firstname":-1}'
        : '{"job._creator.accountInfo.firstname":1}';
    }

    setSort(tmpSort);
    getMavRatings();
  };

  const renderReviews = (key) => {
    const review = ratings[key];

    return (
      <div className="c-list-entry" key={key}>
        <div className="c-list-entry__comment">
          {review.comment ? (
            <b>{'"' + review.comment + '"'}</b>
          ) : (
            "(no comment)"
          )}
        </div>
        <div className="c-list-entry__rating">
          <div className="c-rating c-rating--list">
            <fieldset className="c-rating__wrap">
              {[5, 4, 3, 2, 1].map((starValue) => (
                <React.Fragment key={starValue}>
                  <input
                    tabIndex="-1"
                    className="c-rating__input"
                    type="radio"
                    name={"stars" + key}
                    value={starValue}
                    checked={review.stars === starValue}
                    readOnly
                  />
                  <label className="c-rating__label-read-only-small">
                    <span className="u-visually-hidden">{starValue} stars</span>
                  </label>
                </React.Fragment>
              ))}
            </fieldset>
          </div>
        </div>
        <div className="c-list-entry__clientname ">
          {review.job._creator.accountInfo.firstname +
            " " +
            review.job._creator.accountInfo.surname}
        </div>
        <div className="c-list-entry__reviews">
          {review.job.creationDate
            ? spoofedISODateProcess(review.job.creationDate, "printDateNice")
            : "(in progress)"}
        </div>
        <button
          type="button"
          className="c-btn c-btn--teal c-btn--small"
          onClick={(e) =>
            toScreen(e, navigate, "/job/" + review.job.short_id)
          }
        >
          View job
        </button>
        <button
          type="button"
          className="c-btn c-btn--red c-btn--small"
          onClick={(e) => deleteRating(e, key)}
        >
          Delete
        </button>
        <button
          type="button"
          className="c-btn c-btn--green c-btn--small"
          onClick={(e) => editRating(e, key)}
        >
          Edit
        </button>
      </div>
    );
  };

  const nextPage = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      getMavRatings();
    }
  };

  const prevPage = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      getMavRatings();
    }
  };

  useEffect(() => {
    getMavRatings();
  }, []);

  return (
    <div className="l-base-layout__content">
      <h1>Admin / Mav / Reviews</h1>

      <div className="c-hr"></div>

      <div className="c-subheader-info-panel">
        <div className="c-subheader-info-panel__primary u-uppercase">
          ID: {mavDetails.short_id}
        </div>
      </div>

      <div className="c-hr"></div>

      <div className="l-row">
        <div className="l-col-18">
          <div className="c-avatar u-margin-bottom-0">
            <div
              className="c-avatar__pic-wrap"
              onClick={(e) => {
                mavDetails.totalRatings && mavDetails.totalRatings > 0
                  ? toScreen(
                    e,
                    navigate,
                    "/mav/" + mavDetails.short_id
                  )
                  : null;
              }}
            >
              <img
                src={
                  mavDetails.accountInfo.avatar &&
                    mavDetails.accountInfo.avatar != ""
                    ? serverUrl +
                    userImagesFolder +
                    "/" +
                    mavDetails.short_id +
                    "/" +
                    mavDetails.accountInfo.avatar +
                    imageFormat
                    : defaultAvatarImage
                }
                alt="avatar"
                className="c-avatar__pic"
                onError={(e) => {
                  e.target.src = defaultAvatarImage;
                }}
              />
            </div>
          </div>
        </div>
        <div className="l-col-41">
          <div className="c-rating u-margin-left-xs u-margin-bottom-0 u-margin-top-0">
            <fieldset
              className="c-rating__wrap"
              onClick={(e) => {
                toScreen(
                  e,
                  navigate,
                  "/mav/" + mavDetails.short_id
                );
              }}
            >
              {[5, 4, 3, 2, 1].map((starValue) => (
                <React.Fragment key={starValue}>
                  <input
                    tabIndex="-1"
                    className="c-rating__input"
                    type="radio"
                    id={`star${starValue}`}
                    name="stars"
                    value={starValue}
                    checked={mavDetails.ratingAsMav === starValue}
                    readOnly
                  />
                  <label
                    className="c-rating__label-read-only"
                    htmlFor={`star${starValue}`}
                  >
                    <span className="u-visually-hidden">{starValue} stars</span>
                  </label>
                </React.Fragment>
              ))}
            </fieldset>
          </div>
          <div className="u-margin-left-s">
            {mavDetails.totalRatings == 0 ||
              mavDetails.totalRatings == "" ? (
              <p className="c-rating__subtext">(No reviews yet)</p>
            ) : (
              <a
                href={
                  mavDetails.totalRatings &&
                    mavDetails.totalRatings > 0
                    ? `/mav/${mavDetails.short_id}`
                    : "#"
                }
              >
                {mavDetails.totalRatings} driver reviews
              </a>
            )}
          </div>
        </div>
        <div className="l-col-41">
          <button
            className="c-btn c-btn--small c-btn--full-width u-margin-bottom-xs  c-btn--grey"
            onClick={(e) => {
              toScreen(
                e,
                navigate,
                "/mav/" + mavDetails.short_id
              );
            }}
          >
            View MAV
          </button>
          <button
            className="c-btn c-btn--small c-btn--full-width  c-btn--green"
            onClick={(e) => {
              toScreen(
                e,
                navigate,
                "/message/" + mavDetails.short_id,
                false,
                {
                  ...extraAttributes,
                  previousPath:
                    window.location.pathname ?? window.history.state.usr?.previousPath
                }
              );
            }}
          >
            Contact MAV
          </button>
        </div>
      </div>

      <div className="l-col-100">
        {/* Pagination top */}
        <div className="c-pagination">
          <button
            type="button"
            className="c-pagination__prev"
            onClick={(e) => prevPage(e)}
          >
            &lt;&lt; Prev
          </button>
          {currentPage} / {totalPages}
          <button
            type="button"
            className="c-pagination__next"
            onClick={(e) => nextPage(e)}
          >
            Next &gt;&gt;
          </button>
        </div>
        <div className="c-hr u-margin-bottom-0"></div>

        {/* Ratings list header */}
        <div>
          <div className="c-list-entry c-list-entry__header">
            <div className="c-list-entry__comment c-list-filter"></div>
            <div className="c-list-entry__rating c-list-filter">
              <button
                className={
                  sort == '{"ratings[0].stars":-1}'
                    ? "c-list-filter__button c-list-filter__button--icon-up"
                    : sort == '{"ratings[0].stars":1}'
                      ? "c-list-filter__button c-list-filter__button--icon-down"
                      : "c-list-filter__button c-list-filter__button--faded"
                }
                onClick={(e) => sortRatings(e, "stars")}
              >
                Stars
              </button>
            </div>
            <div className="c-list-entry__clientname c-list-filter">
              <button
                className={
                  sort ==
                    '{"job._creator.accountInfo.firstname":-1}'
                    ? "c-list-filter__button c-list-filter__button--icon-up"
                    : sort ==
                      '{"job._creator.accountInfo.firstname":1}'
                      ? "c-list-filter__button c-list-filter__button--icon-down"
                      : "c-list-filter__button c-list-filter__button--faded"
                }
                onClick={(e) => sortRatings(e, "client")}
              >
                Client
              </button>
            </div>
            <div className="c-list-entry__reviews c-list-filter">
              <button
                className={
                  sort == '{"job.creationDate":-1}'
                    ? "c-list-filter__button c-list-filter__button--icon-up"
                    : sort == '{"job.creationDate":1}'
                      ? "c-list-filter__button c-list-filter__button--icon-down"
                      : "c-list-filter__button c-list-filter__button--faded"
                }
                onClick={(e) => sortRatings(e, "date")}
              >
                Job date
              </button>
            </div>
          </div>
        </div>

        {/* Ratings list */}
        <div>{Object.keys(ratings).map(renderReviews)}</div>
        <div className="c-hr u-margin-bottom-0 u-clear-both"></div>

        {/* Pagination bottom */}
        <div className="c-pagination">
          <button
            type="button"
            className="c-pagination__prev"
            onClick={(e) => prevPage(e)}
          >
            &lt;&lt; Prev
          </button>
          {currentPage} / {totalPages}
          <button
            type="button"
            className="c-pagination__next"
            onClick={(e) => nextPage(e)}
          >
            Next &gt;&gt;
          </button>
        </div>
      </div>

      <EditRating ref={editRatingRef} saveRating={saveRating} />
    </div>
  );
};

Reviews.propTypes = {
  userDetails: PropTypes.object.isRequired,
  mavId: PropTypes.string.isRequired,
  updateState: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default withNavigate(Reviews);