import React from "react";
import $ from "jquery";
import errorImage from "../../img/app/404-error.svg";

class NotFound extends React.Component {
	componentDidMount() {
		$("body").addClass("l-no-header l-no-footer");
	}

	componentWillUnmount() {
		$("body").removeClass("l-no-header l-no-footer");
	}

	handleGoHome = () => {
		window.location.href = "/";
	};

	render() {
		return (
			<div className="l-base-layout__content">
				<div className="l-content-width l-main-content-padding not-found-container">
					<div className="not-found-content">
						<div className="image-container">
							<img src={errorImage} alt="image" width={150} />
						</div>
						<h1 className="c-page-title not-found-title ">Page Not Found</h1>
						<p className="not-found-message">
							We can't seem to find the page you're looking for.
						</p>
						<p className="not-found-message2">Please navigate to another page.</p>
						<button className="c-btn bg-success c-btn--large" onClick={this.handleGoHome}>
							Go Back Home
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default NotFound;
