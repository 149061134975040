import { serverCall } from "../modules/ServerComms";

function getMonthlyRpt(queryParams, theCallback, theJWTToken) {
  const { theYear, userType } = queryParams;
  const url = `/api/admin/monthlyReport?userType=${userType}&year=${theYear}`;

  const handleCallAPI = (resStatus, resJson) => {
    var responseString;

    if (resStatus == "400" || resStatus == 400) {
      // jobsfound | nouserdata nouserwiththatemail unauthorised nojobsfound usernotactive

      if (resJson.errorString == "nouserdata") {
        responseString = "ERROR: NO USER DATA";
      } else if (resJson.errorString == "nouserwiththatemail") {
        responseString = "ERROR: USER NOT FOUND";
      } else if (resJson.errorString == "usernotactive") {
        responseString = "ERROR: USER NOT ACTIVE";
      } else if (resJson.errorString == "unauthorised") {
        responseString = "ERROR: USER NOT AUTHORISED";
      } else if (resJson.errorString == "nojobsfound") {
        responseString = "ERROR: NO JOBS FOUND";
      } else if (resJson.errorString == "invalidfilter") {
        responseString = "ERROR: INVALID FILTER";
      } else if (resJson.errorString == "invalidsearch") {
        responseString = "ERROR: INVALID SEARCH";
      } else if (resJson.errorString == "invalidsorts") {
        responseString = "ERROR: INVALID SORT";
      } else if (resJson.errorString == "invalidpage") {
        responseString = "ERROR: INVALID PAGE";
      } else {
        responseString = "ERROR: UNKNOWN";
      }

      return theCallback(resStatus, responseString, resJson);
    } else if (resStatus == "401" || resStatus == 401) {
      if (resJson.errorString == "unauthorised") {
        responseString = "ERROR: USER NOT AUTHORISED";
      } else if (resJson.errorString == "userblocked") {
        responseString = "ERROR: USER BLOCKED";
      } else if (resJson.errorString == "outdatedappversion") {
        responseString = "ERROR: OUTDATED VERSION";
      } else {
        responseString = "ERROR: UNKNOWN";
      }

      return theCallback(resStatus, responseString, resJson);
    } else if (
      resStatus == "504" ||
      resStatus == 504 ||
      resStatus == "502" ||
      resStatus == 502
    ) {
      responseString = "ERROR: TIMEOUT";
      return theCallback(resStatus, responseString, false);
    } else if (resStatus == "200" || resStatus == 200) {
      if (resJson.successString == "usersfound") {
      }

      return theCallback(resStatus, responseString, resJson);
    }
  };
  serverCall("GET", url, null, handleCallAPI, theJWTToken);
}

export { getMonthlyRpt };
