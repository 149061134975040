import React from 'react';
import _ from 'lodash';
import { updateState } from '../modules/Generic';
import { handleInputChange, handleInputBlur } from '../modules/Inputs';
import PropTypes from 'prop-types';


class EditRating extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            identifier: false,
            isVisible: false,
            theReview: {
                client: '',
                comment: '',
                stars: 0,
                status: false
            },
            validation: {
                'theReview.comment': true
            }
        };

        this.updateState = updateState.bind(this);
        this.handleInputChange = handleInputChange.bind(this);
        this.handleInputBlur = handleInputBlur.bind(this);

        this.reviewStar_click = this.reviewStar_click.bind(this);
        this.reviewStar_move = this.reviewStar_move.bind(this);

        this.goEditRating = this.goEditRating.bind(this);
        this.editRating_close = this.editRating_close.bind(this);
        this.editRating_save = this.editRating_save.bind(this);
        this.editRating_discard = this.editRating_discard.bind(this);

    }

    goEditRating(event, theIdentifier, theComment, theStars, theClient) {

        if (event) {
            event.preventDefault();
        }

        const goEditRatingNow = () => {

            this.setState({
                ...this.state,
                identifier: theIdentifier,
                isVisible: true,
                theReview: {
                    client: theClient || '',
                    comment: theComment || '',
                    stars: theStars,
                }
            });

        }

        goEditRatingNow();

        document.closeModalExtra = (e) => {
            this.editRating_close(false);
        };


    }

    editRating_close(event) {

        if (event) {
            event.preventDefault();
        }

        this.setState({
            ...this.state,
            identifier: false,
            isVisible: false
        }
        );

    }

    editRating_discard(event) {

        if (event) {
            event.preventDefault();
        }

        this.setState({
            ...this.state,
            identifier: false,
            isVisible: false
        });

    }

    editRating_save(event) {

        if (event) {
            event.preventDefault();
        }
        this.props.saveRating(false, this.state.identifier, this.state.theReview.comment, parseInt(this.state.theReview.stars));

        this.editRating_close();


    }

    reviewStar_click(event, theStars) {

        event.preventDefault();

        this.setState({
            ...this.state,
            theReview: {
                ...this.state.theReview,
                stars: theStars.toString()
            },
            validation: {
                ...this.state.validation,
                ['theReview.stars']: true
            }
        });


    }


    reviewStar_move(event, theStars) {

        var touchobj = event.changedTouches[0];

        const checkHit = (theElem) => {


            if (touchobj.clientX > theElem.left && touchobj.clientX < theElem.right) {
                return true
            } else {
                return false
            }

        }

        var star1ElemRect = document.getElementById('labelForStar1').getBoundingClientRect();
        var star2ElemRect = document.getElementById('labelForStar2').getBoundingClientRect();
        var star3ElemRect = document.getElementById('labelForStar3').getBoundingClientRect();
        var star4ElemRect = document.getElementById('labelForStar4').getBoundingClientRect();
        var star5ElemRect = document.getElementById('labelForStar5').getBoundingClientRect();

        var theHitStar = false;

        if (checkHit(star1ElemRect)) {
            theHitStar = 1;
        } else if (checkHit(star2ElemRect)) {
            theHitStar = 2;
        } else if (checkHit(star3ElemRect)) {
            theHitStar = 3;
        } else if (checkHit(star4ElemRect)) {
            theHitStar = 4;
        } else if (checkHit(star5ElemRect)) {
            theHitStar = 5;
        }

        if (theHitStar && theHitStar != this.state.theReview.stars) {

            this.setState({
                ...this.state,
                theReview: {
                    ...this.state.theReview,
                    stars: theHitStar.toString()
                },
                validation: {
                    ...this.state.validation,
                    ['theReview.stars']: true
                }
            });

        }


    }



    render() {

        return (
            <div className={this.state.isVisible ? "c-modal c-modal--visible" : "c-modal"}>
                <div className="c-modal__bg js-close-modal"></div>
                <div className="c-modal__inner ">
                    <p className="c-modal__title">{this.state.theReview.client ? 'Edit ' + this.state.theReview.client + '\'s Review' : 'Edit Review'}</p>
                    <form id="editform" className="c-modal__form" target="_self">
                        <div className="c-rating">
                            <fieldset className="c-rating__wrap c-rating__wrap-modal needsclick" onTouchMove={(e) => { this.reviewStar_move(e) }} id="ratingWrap">
                                <input tabIndex="1" className="c-rating__input needsclick" type="radio" id="star5" name="theReview.stars" readOnly checked={this.state.theReview.stars === 5 || this.state.theReview.stars === '5'} />
                                <label className="c-rating__label-read-only-large needsclick" htmlFor="star5" id="labelForStar5" onClick={(e) => { this.reviewStar_click(e, 5) }}>
                                    <span className="u-visually-hidden">5 stars</span>
                                </label>
                                <input tabIndex="2" className="c-rating__input needsclick" type="radio" id="star4" name="theReview.stars" readOnly checked={this.state.theReview.stars === 4 || this.state.theReview.stars === '4'} />
                                <label className="c-rating__label-read-only-large needsclick" htmlFor="star4" id="labelForStar4" onClick={(e) => { this.reviewStar_click(e, 4) }}>
                                    <span className="u-visually-hidden">4 stars</span>
                                </label>
                                <input tabIndex="3" className="c-rating__input needsclick" type="radio" id="star3" name="theReview.stars" readOnly checked={this.state.theReview.stars === 3 || this.state.theReview.stars === '3'} />
                                <label className="c-rating__label-read-only-large needsclick" htmlFor="star3" id="labelForStar3" onClick={(e) => { this.reviewStar_click(e, 3) }}>
                                    <span className="u-visually-hidden">3 stars</span>
                                </label>
                                <input tabIndex="4" className="c-rating__input needsclick" type="radio" id="star2" name="theReview.stars" readOnly checked={this.state.theReview.stars === 2 || this.state.theReview.stars === '2'} />
                                <label className="c-rating__label-read-only-large needsclick" htmlFor="star2" id="labelForStar2" onClick={(e) => { this.reviewStar_click(e, 2) }}>
                                    <span className="u-visually-hidden">2 stars</span>
                                </label>
                                <input tabIndex="5" className="c-rating__input needsclick" type="radio" id="star1" name="theReview.stars" readOnly checked={this.state.theReview.stars === 1 || this.state.theReview.stars === '1'} />
                                <label className="c-rating__label-read-only-large needsclick" htmlFor="star1" id="labelForStar1" onClick={(e) => { this.reviewStar_click(e, 1) }}>
                                    <span className="u-visually-hidden">1 star</span>
                                </label>
                            </fieldset>
                        </div>


                        <textarea rows="4" id='theReview.comment' name='theReview.comment' value={this.state.theReview.comment} onChange={this.handleInputChange} onFocus={(e) => { }} onBlur={(e) => { this.handleInputBlur(e, this.updateState) }}></textarea>
                    </form>

                    <button className="c-btn c-btn--small c-btn--grey u-margin-bottom-0 u-margin-top-m" onClick={this.editRating_discard}>Discard</button><button className="c-btn c-btn--small c-btn--green u-margin-right-0 u-margin-bottom-0 u-margin-top-m" onClick={this.editRating_save}>Save</button>

                </div>
            </div >


        )
    }



}

EditRating.contextTypes = {
    router: PropTypes.object
}

EditRating.propTypes = {
    // saveRating: PropTypes.func.isRequired
}


export default EditRating;