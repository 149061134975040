import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { getMonthlyRpt } from "../../modules/ReportsManager";
import { showSpinner, closeSpinner, modal, alert } from "../../modules/Dialogs";
import { sessionExpired, userType } from "../../config/GeneralConfig";
import { toScreen } from "../../modules/Routing";
import PropTypes from "prop-types";
import withNavigate from "../withNavigate";

const RptMonthly = ({ userDetails, navigate }) => {
  const [crYear, setCrYear] = useState(new Date().getFullYear());

  const [data, setData] = useState([]);

  const getReport = useCallback(
    (event) => {
      if (event) event.preventDefault();
      showSpinner();

      const queryParams = {
        userType: "mav",
        theYear: crYear,
      };
      getMonthlyRpt(queryParams, getReport_done, userDetails.jwttoken);
    },
    [crYear, userDetails.jwttoken]
  );

  const getReport_done = (resStatus, responseString, resJson) => {
    closeSpinner();

    if (resStatus === 401) {
      modal(sessionExpired);
      toScreen(false, navigate, "/logout");
    } else if (resStatus === 400) {
      setData({});
      alert(responseString);
    } else if (resStatus === 200) {
      setData(resJson?.data);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  const handleInputChange = (event) => {
    setCrYear(event.target.value);
  };

  const renderReport = (key) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dataRow = data[key];

    return (
      <div className="c-list-entry" key={key}>
        <div className="c-list-entry__name">
          <b>{monthNames[dataRow.month - 1]}</b>
        </div>
        <div className="c-list-entry__name">{dataRow?.driverCount}</div>
        <div className="c-list-entry__name">{dataRow?.userCount}</div>
        <div className="c-list-entry__name">{dataRow?.jobCount}</div>
        <div className="c-list-entry__name">{dataRow?.totalQuotes}</div>
        <div className="c-list-entry__name">{dataRow?.notQuoted}</div>
        <div className="c-list-entry__name">{dataRow?.booked}</div>
        <div className="c-list-entry__name">{dataRow?.jobTotalRatings}</div>
        <div className="c-list-entry__name">
          {dataRow.bookedPercentage || 0}
        </div>
      </div>
    );
  };

  return (
    <div className="l-base-layout__content">
      <h1>Admin / Reports / Monthly report</h1>
      <div className="c-hr"></div>
      <div className="l-row">
        <div className="l-col-100 u-padding-top-0 u-padding-bottom-0">
          <div className="c-filter-bar-2">
            <form>
              <div className="item-container">
                <label className="item-label">Year:</label>
                <input
                  className="item-input"
                  type="text"
                  value={crYear}
                  onChange={handleInputChange}
                  name="crYear"
                  placeholder="Year"
                />
                <button
                  type="button"
                  onClick={() => getReport()}
                  className="search-button"
                >
                  Refresh
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="l-col-100">
          <div className="c-hr u-margin-bottom-0"></div>
          <div>
            <div className="c-list-entry c-list-entry__header">
              <div className="c-list-entry__name">
                <b>Month</b>
              </div>
              <div className="c-list-entry__name">
                <b>Drivers</b>
              </div>
              <div className="c-list-entry__name">
                <b>Users</b>
              </div>
              <div className="c-list-entry__name">
                <b>Jobs</b>
              </div>
              <div className="c-list-entry__name">
                <b>Quotes</b>
              </div>
              <div className="c-list-entry__name">
                <b>NotQuoted</b>
              </div>
              <div className="c-list-entry__name">
                <b>Booked</b>
              </div>
              <div className="c-list-entry__name">
                <b>Total Reviews</b>
              </div>
              <div className="c-list-entry__name">
                <b>Conversion Rate</b>
              </div>
            </div>
          </div>
          
          <div>{data.length>0 ? Object.keys(data)?.map(renderReport): <p className="text-danger u-text-align-center my-16">No records found.</p>}</div>
          <div className="c-hr u-margin-bottom-0"></div>
        </div>
      </div>
    </div>
  );
};

RptMonthly.propTypes = {
  userDetails: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default withNavigate(RptMonthly);
