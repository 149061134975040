import { serverUrl, serverTimeout } from "../config/GeneralConfig";
import Version from "../version/Version";

function customHttpClient(
  theType,
  theUrl,
  theJson,
  theCallback,
  theJWTToken,
  timoutMultiplyer,
  accessKeyFlag = false
) {
  var params;

  if (theJson) {
    params = JSON.stringify(theJson);
  } else {
    params = null;
  }

  var xmlHttp = new XMLHttpRequest();

  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState == 4) {
      theCallback(xmlHttp.status, xmlHttp.responseText);
    }
  };

  //   xmlHttp.ontimeout = function (e) {
  //     theCallback("504", false);
  //   };

  xmlHttp.open(theType, serverUrl + theUrl, true);
  //   xmlHttp.timeout = timoutMultiplyer
  //     ? Math.round(timoutMultiplyer * serverTimeout)
  //     : serverTimeout;
  xmlHttp.setRequestHeader("Content-type", "application/json; charset=utf-8");
  xmlHttp.setRequestHeader("Authorization", "Bearer " + theJWTToken);
  xmlHttp.setRequestHeader("ClientType", Version.ClientType);
  xmlHttp.setRequestHeader("AppVersion", Version.AppVersion);
  if (accessKeyFlag) {
    xmlHttp.setRequestHeader("accessKey", "87C798E1CD9B93A3B8BFC3AC6414D");
  }

  xmlHttp.send(params);
}

var serverCall = function (
  theType,
  theUrl,
  theJson = null,
  theCallback,
  theJWTToken,
  timoutMultiplyer,
  accessKeyFlag = false
) {
  customHttpClient(
    theType,
    theUrl,
    theJson,

    function (resStatus, resText) {
      if (resText) {
        var resJson = JSON.parse(resText);
        theCallback(resStatus, resJson);
      } else if (resStatus || resStatus == 0) {
        theCallback(resStatus == 0 ? "504" : resStatus, false);
      }
    },
    theJWTToken,
    timoutMultiplyer,
    accessKeyFlag
  );
};

export { serverCall };
