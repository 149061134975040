import {
	usernameRegExp,
	licenceRegExp,
	genericLicenceRegExp,
	nameRegExp,
	companyNameRegExp,
	jobNameRegExp,
	dobRegExp,
	telephoneRegExp,
	emailRegExp,
	passwordRegExp,
	otpRegExp,
	termsRegExp,
	pushnotificationsRegExp,
	dateRegExp,
	timeRegExp,
	shortcopyRegExp,
	longcopyRegExp,
	objectidRegExp,
	shortidRegExp,
	priceRegExp,
	defaultMaxJobValue,
	searchRegExp,
	pageRegExp,
	creditcardRegExp,
	cvcRegExp,
	messageRulesFlags,
	messageRulesBlocks
} from '../config/GeneralConfig';
import _ from 'lodash';
import moment from 'moment-timezone';
import Big from 'big.js';
import produce from "immer";

function detect_device() {
	if (typeof device !== 'undefined') {
		if (device.platform == 'iOS' || device.version == 'iPhone') {
			return 'iOS';
		} else if (device.platform == 'Android' || device.version == 'Android') {
			return 'Android';
		} else {
			return 'unknown';
		}
	} else {
		return 'browser';
	}
}

function validate_username(theUsername) {
	return usernameRegExp.test(theUsername);
}

function validate_licence(theLicence) {
	return licenceRegExp.test(theLicence);
}

function validate_genericlicence(theGenericLicence) {
	return genericLicenceRegExp.test(theGenericLicence);
}

function validate_name(theName) {
	return nameRegExp.test(theName);
}

function validate_companyname(theCompanyName) {
	return companyNameRegExp.test(theCompanyName);
}

function validate_jobname(theJonName) {
	return jobNameRegExp.test(theJonName);
}

function validate_dob(theDob) {
	return dobRegExp.test(theDob);
}

function validate_telephone(theTelephone) {
	return telephoneRegExp.test(theTelephone);
}

function validate_email(theEmail) {
	return emailRegExp.test(theEmail);
}

function validate_password(thePassword) {
	return passwordRegExp.test(thePassword);
}

function validate_otp(theOtp) {
	return otpRegExp.test(theOtp);
}

function validate_terms(theTerms) {
	return termsRegExp.test(theTerms);
}

function validate_pushnotifications(thePushnotifications) {
	return pushnotificationsRegExp.test(thePushnotifications);
}

function validate_date(theDate) {
	return dateRegExp.test(theDate);
}

function validate_time(theTime) {
	return timeRegExp.test(theTime);
}

function validate_shortcopy(theShortcopy) {
	return shortcopyRegExp.test(theShortcopy);
}

function validate_longcopy(theLongcopy) {
	return longcopyRegExp.test(theLongcopy);
}

function validate_objectid(theObjectid) {
	return objectidRegExp.test(theObjectid);
}

function validate_shortid(theShortid) {
	return shortidRegExp.test(theShortid);
}

function validate_price(thePrice) {
	return (priceRegExp.test(thePrice) || priceRegExp.test(thePrice + '0')) && thePrice < defaultMaxJobValue;
}

function validate_customerprice(thePrice, theCommission) {
	return (priceRegExp.test(thePrice) || priceRegExp.test(thePrice + '0')) && thePrice < (defaultMaxJobValue * (1 + theCommission));
}

function validate_number(theNumber) {

	return !isNaN(parseFloat(theNumber)) && isFinite(theNumber);
}

function validate_search(theSearch) {
	return searchRegExp.test(theSearch);
}

function validate_page(thePage) {
	return pageRegExp.test(thePage);
}

function validate_cardnumber(theCard) {
	return creditcardRegExp.test(theCard);
}

function validate_cvcnumber(theCvc) {
	return cvcRegExp.test(theCvc);
}


function validate_age_from_dob(theDob, theMinAge) {

	var tmpDobSplit = theDob.split('/');
	var tmpDobFormatted = tmpDobSplit[2] + '-' + tmpDobSplit[1] + '-' + tmpDobSplit[0];

	if (moment().diff(tmpDobFormatted, 'years') >= 18) {
		return true;
	} else {
		return false;
	}
	return moment().diff(inputDate, timeUnits);

};

function validate_message(theMessage, theMode, theCallback) {

	var theFlags = [];
	var theBlocks = [];

	_.forEach(messageRulesFlags, (value, key) => {
		var found = false;
		if (_.includes(value.messageTypes, theMode)) {
			_.forEach(value.test, (valueT, keyT) => {
				// if (GeneralConfig[valueT].test(theMessage)) {
				// 	found = true;
				// }
			});
		}
		if (found) {
			theFlags.push(key);
		}
	});

	_.forEach(messageRulesBlocks, (value, key) => {
		var found = false;

		if (_.includes(value.messageTypes, theMode)) {

			_.forEach(value.test, (valueT, keyT) => {
				// if (GeneralConfig[valueT].test(theMessage)) {
				// 	found = true;
				// }
			});
		}
		if (found) {
			theBlocks.push(key);
		}
	});

	return theCallback(theFlags, theBlocks);

}

function getDateAge(theDate, theTime, timeUnits) {

	var tmpDateSplit = theDate.split('/');
	var tmpDateFormatted = tmpDateSplit[2] + '-' + tmpDateSplit[1] + '-' + tmpDateSplit[0];

	var checkDate = moment.tz(new Date(tmpDateFormatted + ' ' + theTime), "Europe/London");//moment().diff(tmpDateFormatted,timeUnits)
	var currentDate = moment.tz(new Date(), "Europe/London");

	return currentDate.diff(checkDate, timeUnits);

};


function timeStamp() {
	return new Date();
};

function leftpad(str, len, ch) {
	str = String(str);
	var i = -1;
	if (!ch && ch !== 0) ch = ' ';
	len = len - str.length;
	while (++i < len) {
		str = ch + str;
	}
	return str;
};

function shortenString(theString, theLength, ellipsis) {
	var theStringShort = '';
	if (!theString) {
		theStringShort = '';
	} else if (theString.length > theLength) {
		if (ellipsis) {
			theStringShort = theString.substring(0, theLength - 3) + '...';
		} else {
			theStringShort = theString.substring(0, theLength);
		}
	} else {
		theStringShort = theString;
	}

	return theStringShort;
};

function acronymString(theString, theLength) {

	var theAcroString = '';
	var splitArray = theString.split(' ');

	if (!theString || theString == "") {
		theAcroString = '';
	} else {
		var arrayCount = 0;
		while (theAcroString.length < theLength && splitArray[arrayCount]) {
			theAcroString += splitArray[arrayCount].substring(0, 1);
			arrayCount++;
		}
	}

	return theAcroString.toUpperCase();
};

function calculatePricesWithCommission(theBasePrice, theTotalPrice, theCommission, theDecimals, isDisplay) {

	// DIFFERENT

	if (theDecimals === undefined) {
		theDecimals = 2;
	}

	var returnPrices = {
		basePrice: 0,
		totalPrice: 0,
		commission: 0
	};

	if (isDisplay) {

		if (theBasePrice) {

			returnPrices.basePrice = Big(theBasePrice).toFixed(theDecimals);
			returnPrices.commission = Big(theBasePrice).times(theCommission).toFixed(theDecimals);
			returnPrices.totalPrice = Big(theBasePrice).plus(Big(theBasePrice).times(theCommission)).toFixed(theDecimals);

		} else if (theTotalPrice) {

			returnPrices.totalPrice = Big(theTotalPrice).toFixed(theDecimals);
			returnPrices.commission = Big(theTotalPrice).div(1 + theCommission).times(theCommission).toFixed(theDecimals);
			returnPrices.basePrice = Big(theTotalPrice).minus(Big(theTotalPrice).div(1 + theCommission).times(theCommission)).toFixed(theDecimals);

		}

	} else {

		if (theBasePrice) {

			returnPrices.basePrice = parseFloat(Big(theBasePrice).toFixed(theDecimals));
			returnPrices.commission = parseFloat(Big(theBasePrice).times(theCommission).toFixed(theDecimals));
			returnPrices.totalPrice = parseFloat(Big(theBasePrice).plus(Big(theBasePrice).times(theCommission)).toFixed(theDecimals));

		} else if (theTotalPrice) {

			returnPrices.totalPrice = parseFloat(Big(theTotalPrice).toFixed(theDecimals));
			returnPrices.commission = parseFloat(Big(theTotalPrice).div(1 + theCommission).times(theCommission).toFixed(theDecimals));
			returnPrices.basePrice = parseFloat(Big(theTotalPrice).minus(Big(theTotalPrice).div(1 + theCommission).times(theCommission)).toFixed(theDecimals));

		}

	}



	return returnPrices;

}

function printPriceAsDecimal(thePrice, theDecimals) {
	return Big(thePrice).div(Big(100)).toFixed(theDecimals);
}

function printPoundsAsDecimal(thePrice, theDecimals) {
	return Big(thePrice).toFixed(theDecimals);
}

function decodeBase64Image(dataString) {

	var matches;
	var response = {};

	try {
		matches = dataString.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/)
	}
	catch (error) {
		return false;
	}

	if (matches.length !== 3) {
		return false;
	}


	response.type = matches[1];
	response.data = new Buffer(matches[2], 'base64');

	return response;
}

function dateProcess(inputDate, returnType, convertZone) {

	///START NEWLY ADDED
	if (inputDate && inputDate instanceof Date == false && inputDate.indexOf('/') !== -1) {
		if (inputDate.indexOf(' ') !== -1) {
			var theDate = inputDate.split(' ')[0].split('/');
			var theTime = inputDate.split(' ')[1].split(':');
			inputDate = new Date(theDate[2], theDate[1] - 1, theDate[0], theTime[0], theTime[1]);
		} else {
			var theDate = inputDate.split('/');
			inputDate = new Date(theDate[2], theDate[1] - 1, theDate[0]);
		}
	} else if (inputDate && !inputDate instanceof Date) {
		inputDate = new Date(inputDate);
	}
	///END NEWLY ADDED

	var inputMom;

	if (!inputDate) {

		inputDate = new Date();
		inputMom = moment.tz(inputDate, "Europe/London");

	} else if (convertZone) {

		inputMom = moment.tz(inputDate, "Europe/Paris");

	} else {

		inputMom = moment.tz(inputDate, "Europe/London");

	}

	moment.locale('en-gb');

	var theMom = inputMom.tz("Europe/London");

	if (returnType == "standardDate") {
		return theMom.toISOString();
	}

	if (returnType == "printDate") {
		return theMom.format('YYYY-MM-DD').toLowerCase();
	}

	if (returnType == "printDateNice") {
		return theMom.format('DD/MM/YYYY').toLowerCase();
	}

	if (returnType == "printDateNiceShort") {
		return theMom.format('DD/MM').toLowerCase();
	}

	if (returnType == "printDay") {
		return theMom.format('dddd Do MMMM YYYY').toLowerCase();
	}

	if (returnType == "printDayOfWeek") {
		return theMom.format('dddd');
	}


	if (returnType == "printDayCamel") {
		return theMom.format('dddd Do MMMM YYYY');
	}

	if (returnType == "printTime") {
		return theMom.format('HH:mm').toLowerCase();
	}

	if (returnType == "printDayAndTime") {
		return theMom.format('dddd Do MMMM YYYY, HH:mm').toLowerCase();
	}

	if (returnType == "printDayAndTimeCamel") {
		return theMom.format('dddd Do MMMM YYYY [at] HH:mm');
	}

	if (returnType == "printDayAndTimeCamelAlt") {
		return theMom.format('Do MMMM, YYYY');
	}


	if (returnType == "returnDate") {
		return theMom.format('YYYY-MM-DD HH:mm');
	}

	if (returnType == "printForMixpanel") {
		return theMom.format('YYYY-MM-DDTHH:MM:SS');
	}

	if (returnType == "printForAuditNote") {
		return theMom.format('DD/MM/YYYY hh:mm');
	}


};

function lodashDiff(object, base) {

	function changes(object, base) {
		const accumulator = {};
		Object.keys(base).forEach((key) => {
			if (object[key] === undefined) {
				accumulator[`-${key}`] = base[key];
			}
		});
		if (!_.isObject(object) || _.isDate(object)) {
			return object;
		} else {
			return _.transform(
				object,
				(accumulator, value, key) => {
					if (base[key] === undefined) {
						accumulator[`+${key}`] = value;
					} else if (!_.isEqual(value, base[key]) && key !== '_id') {
						accumulator[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
					}
				},
				accumulator
			);
		}
	}
	//return JSON.stringify(changes(object, base), null, 4);
	return changes(object, base);
}

function updateState(event, theUpdates, theCallback, doTrace) {

	if (event) {
		event.preventDefault();
	}


	const updatedState = produce(this.state, draftState => {
		_.forEach(theUpdates, (value, key) => {
			_.set(draftState, key, value);

		});

	});

	if (doTrace) { console.log(lodashDiff(updatedState, this.state)); }

	if (!_.isEqual(updatedState, this.state)) {

		this.setState(updatedState, () => { if (theCallback) { theCallback(); } });

	} else if (theCallback) {

		theCallback();

	}
}

function applyStateToParent(theUpdateState, theCallback) {

	let updateItems = {};

	_.forEach(this.state, (value, key) => {
		if (key != 'ignore') {
			updateItems[key] = value;
		}
	})

	theUpdateState(false, updateItems, theCallback);

}

function applyPropsToState(theNewProps, theUpdateState, theCallback) {

	let updateItems = {};

	_.forEach(theNewProps, (value, key) => {
		if (key != 'ignore' && _.get(this.state, key) != null) {
			updateItems[key] = value;
		}
	})

	theUpdateState(false, updateItems, theCallback);

}


function weekDaysStartingToday() {

	let testDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	let newMom = moment(new Date());
	let orderedDays = [newMom.format('dddd'), newMom.add(1, 'day').format('dddd'), newMom.add(1, 'day').format('dddd'), newMom.add(1, 'day').format('dddd'), newMom.add(1, 'day').format('dddd'), newMom.add(1, 'day').format('dddd'), newMom.add(1, 'day').format('dddd')];

	return _.isEqual(testDays.sort(), [...orderedDays].sort()) ? orderedDays : testDays;

}

function thisWeekString(theDays) {

	let daysTotal = _.size(_.filter(theDays, (n) => { return n }));
	let printedCount = 0;
	let daysString = '';

	_.forEach(theDays, (value, key) => {
		if (value == true) {
			printedCount++;
			if (daysString == '') {
				daysString += key;
			} else {
				if (printedCount == daysTotal) {
					daysString += ' or ' + key;
				} else {
					daysString += ', ' + key;
				}

			}
		}
	})

	return daysString

}



function momentToSpoofedISO(theMoment) {
	return theMoment.format('YYYY-MM-DD') + 'T' + theMoment.format('HH:mm:ss.SSS') + 'Z';
}

function spoofedISOToMoment(theISO) {

	let date = theISO.split('T')[0]
	let time = theISO.split('T')[1]

	var newMoment = moment(new Date());
	newMoment.year(parseInt(date.split('-')[0]));
	newMoment.month(parseInt(date.split('-')[1]) - 1);
	newMoment.date(parseInt(date.split('-')[2]))

	return newMoment
		.hour(parseInt(time.split(':')[0]))
		.minute(parseInt(time.split(':')[1]))
		.second(0).millisecond(0);
}

function spoofedISODateProcess(theISO, returnType) {

	let theMom = spoofedISOToMoment(theISO);

	if (returnType == "standardDate") {
		return theMom.toISOString();
	}

	if (returnType == "printDate") {
		return theMom.format('YYYY-MM-DD').toLowerCase();
	}

	if (returnType == "printDateNice") {
		return theMom.format('DD/MM/YYYY').toLowerCase();
	}

	if (returnType == "printDateNiceShort") {
		return theMom.format('DD/MM').toLowerCase();
	}

	if (returnType == "printDay") {
		return theMom.format('dddd Do MMMM YYYY').toLowerCase();
	}

	if (returnType == "printDayOfWeek") {
		return theMom.format('dddd');
	}

	if (returnType == "printDayCamel") {
		return theMom.format('dddd Do MMMM YYYY');
	}

	if (returnType == "printTime") {
		return theMom.format('HH:mm').toLowerCase();
	}

	if (returnType == "printDayAndTime") {
		return theMom.format('dddd Do MMMM YYYY, HH:mm').toLowerCase();
	}

	if (returnType == "printDayAndTimeCamel") {
		return theMom.format('dddd Do MMMM YYYY [at] HH:mm');
	}

	if (returnType == "printDayAndTimeCamelAlt") {
		return theMom.format('Do MMMM, YYYY');
	}

	if (returnType == "returnDate") {
		return theMom.format('YYYY-MM-DD HH:mm');
	}

	if (returnType == "printForMixpanel") {
		return theMom.format('YYYY-MM-DDTHH:MM:SS');
	}

	if (returnType == "printForAuditNote") {
		return theMom.format('DD/MM/YYYY hh:mm');
	}


};

export {
	detect_device,

	validate_username,
	validate_licence,
	validate_genericlicence,
	validate_name,
	validate_companyname,
	validate_jobname,
	validate_dob,
	validate_telephone,
	validate_email,
	validate_password,
	validate_otp,
	validate_terms,
	validate_date,
	validate_time,
	validate_shortcopy,
	validate_longcopy,
	validate_objectid,
	validate_shortid,
	validate_price,
	validate_customerprice,
	validate_number,
	validate_search,
	validate_page,
	validate_cardnumber,
	validate_cvcnumber,
	validate_pushnotifications,
	validate_message,
	validate_age_from_dob,
	getDateAge,
	timeStamp,
	leftpad,
	shortenString,
	acronymString,
	calculatePricesWithCommission,
	printPriceAsDecimal,
	printPoundsAsDecimal,
	decodeBase64Image,
	dateProcess,
	lodashDiff,
	updateState,
	applyStateToParent,
	applyPropsToState,
	weekDaysStartingToday,
	thisWeekString,
	momentToSpoofedISO,
	spoofedISOToMoment,
	spoofedISODateProcess
}