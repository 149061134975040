import { serverCall } from "../modules/ServerComms";

const errObjects = [
	{
		errCode: "novalidjobspecitemtype",
		errString: "ERROR: NO VALID JOB SPEC ITEM TYPE",
	},
	{
		errCode: "novalidjobspecitemweight",
		errString: "ERROR: NO VALID JOB SPEC ITEM WEIGHT",
	},
	{
		errCode: "novalidjobspecitemweightextra",
		errString: "ERROR: NO VALID JOB SPEC ITEM WEIGHT EXTRA",
	},
	{ errCode: "novalidjobspectime", errString: "ERROR: NO VALID JOB SPEC TIME" },
	{ errCode: "novalidjobstatus", errString: "ERROR: NO VALID JOB STATUS" },
	{ errCode: "novalidname", errString: "ERROR: NO VALID NAME" },
	{ errCode: "novalidpassword", errString: "ERROR: NO VALID PASSWORD" },
	{
		errCode: "novalidpublicliabilityinsurance",
		errString: "ERROR: NO VALID PUBLIC LIABILITY INSURANCE",
	},
	{
		errCode: "novalidpushnotificaions",
		errString: "ERROR: NO VALID PUSH NOTIFICATIONS SETTING",
	},
	{ errCode: "novalidquoteid", errString: "ERROR: NO VALID QUOTE ID" },
	{
		errCode: "novalidquotespecnumbermen",
		errString: "ERROR: NO VALID QUOTE SPEC NUMBER OF MEN",
	},
	{
		errCode: "novalidquotespecnumbervans",
		errString: "ERROR: NO VALID QUOTE SPEC NUMBER OF VANS",
	},
	{
		errCode: "novalidquotespecprice",
		errString: "ERROR: INVALID QUOTE SPEC PRICE",
	},
	{ errCode: "novalidquotestatus", errString: "ERROR: NO VALID QUOTE STATUS" },
	{ errCode: "novalidtelephone", errString: "ERROR: NO VALID TELEPHONE" },
	{ errCode: "novalidusername", errString: "ERROR: NO VALID USERNAME" },
	{ errCode: "novalidvatnumber", errString: "ERROR: NO VALID VAT NUMBER" },
	{
		errCode: "nvaliddestinationaddress",
		errString: "ERROR: INVALID DESTINATION ADDRESS",
	},
	{
		errCode: "nvalidoriginaddress",
		errString: "ERROR: INVALID ORIGIN ADDRESS",
	},
	{ errCode: "outdatedappversion", errString: "ERROR: OUTDATED VERSION" },
	{ errCode: "quotenotsaved", errString: "ERROR: QUOTE NOT SAVED" },
	{
		errCode: "stripeaccountemailexists",
		errString: "ERROR: A STRIPE ACCOUNT EXISTS AT THIS EMAIL ADD",
	},
	{ errCode: "termsnotagreed", errString: "ERROR: TERMS NOT AGREED" },
	{ errCode: "unauthorised", errString: "ERROR: USER NOT AUTHORISED" },
	{ errCode: "userblocked", errString: "ERROR: USER BLOCKED" },
	{ errCode: "useremailexists", errString: "ERROR: USER EMAIL EXISTS" },
	{ errCode: "usernotactive", errString: "ERROR: USER NOT ACTIVE" },
	{ errCode: "usernotsaved", errString: "ERROR: USER NOT SAVED" },
	{ errCode: "userunderage", errString: "ERROR: USER UNDERAGE" },
	{ errCode: "userusernameexists", errString: "ERROR: USER NAME EXISTS" },
];

function getResponseString(pResStatus, pErrorString) {
	let objError = errObjects.find(
		(errObject) => errObject.errCode === pResStatus
	);
	if (objError) {
		return objError.errString;
	} else {
		return pErrorString;
	}
}

function getHome(theUser, theCallback, theJWTToken) {
	serverCall(
		"POST",
		"/api/admin/getdashboard",
		theUser,
		function (resStatus, resJson) {
			let responseString;
			if (resStatus == "200" || resStatus == 200) {
				if (resJson.successString == "adminhomefound") {
					responseString = "SUCCESS: ADMIN HOME FOUND FOUND";
				}

				return theCallback(resStatus, responseString, resJson);
			} else {
				let responseString = getResponseString(resStatus, resJson.errorString);
				return theCallback(resStatus, responseString, false);
			}
		},
		theJWTToken
	);
}

function getUsers(theUser, theCallback, theJWTToken) {
	serverCall(
		"POST",
		"/api/getusers",
		theUser,
		function (resStatus, resJson) {
			let responseString;
			if (resStatus == "200" || resStatus == 200) {
				if (resJson.successString == "usersfound") {
					responseString = "SUCCESS: USERS FOUND";
				}
				return theCallback(resStatus, responseString, resJson);
			} else {
				let responseString = getResponseString(resStatus, resJson.errorString);
				return theCallback(resStatus, responseString, false);
			}
		},
		theJWTToken
	);
}
function getUsersClients(queryParams, theCallback, theJWTToken) {
	const { filter, search, page, limit, sort } = queryParams;
	const url = `/api/admin/v2/clients?page=${page}&limit=${limit}${filter ? `&filter=${filter}` : ""
		}${sort ? `&${sort}` : ""}${search ? `&search=${search}` : ""}`;
	const handleCallAPI = (resStatus, resJson) => {
		var responseString;

		if (resStatus == "400" || resStatus == 400) {
			// jobsfound | nouserdata nouserwiththatemail unauthorised nojobsfound usernotactive

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "nouserwiththatemail") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "nojobsfound") {
				responseString = "ERROR: NO JOBS FOUND";
			} else if (resJson.errorString == "invalidfilter") {
				responseString = "ERROR: INVALID FILTER";
			} else if (resJson.errorString == "invalidsearch") {
				responseString = "ERROR: INVALID SEARCH";
			} else if (resJson.errorString == "invalidsorts") {
				responseString = "ERROR: INVALID SORT";
			} else if (resJson.errorString == "invalidpage") {
				responseString = "ERROR: INVALID PAGE";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);
		} else if (resStatus == "401" || resStatus == 401) {
			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);
		} else if (
			resStatus == "504" ||
			resStatus == 504 ||
			resStatus == "502" ||
			resStatus == 502
		) {
			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);
		} else if (resStatus == "200" || resStatus == 200) {
			if (resJson.successString == "usersfound") {
			}

			return theCallback(resStatus, responseString, resJson);
		}
	};
	serverCall("GET", url, null, handleCallAPI, theJWTToken);
}
function getUsersDrivers(queryParams, theCallback, theJWTToken) {
	const { filter, search, page, limit, sort } = queryParams;
	const url = `/api/admin/v2/drivers?page=${page}&limit=${limit}${filter ? `&filter=${filter}` : ""
		}${sort ? `&${sort}` : ""}${search ? `&search=${search}` : ""}`;
	const handleCallAPI = (resStatus, resJson) => {
		var responseString;

		if (resStatus == "400" || resStatus == 400) {
			// jobsfound | nouserdata nouserwiththatemail unauthorised nojobsfound usernotactive

			if (resJson.errorString == "nouserdata") {
				responseString = "ERROR: NO USER DATA";
			} else if (resJson.errorString == "nouserwiththatemail") {
				responseString = "ERROR: USER NOT FOUND";
			} else if (resJson.errorString == "usernotactive") {
				responseString = "ERROR: USER NOT ACTIVE";
			} else if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "nojobsfound") {
				responseString = "ERROR: NO JOBS FOUND";
			} else if (resJson.errorString == "invalidfilter") {
				responseString = "ERROR: INVALID FILTER";
			} else if (resJson.errorString == "invalidsearch") {
				responseString = "ERROR: INVALID SEARCH";
			} else if (resJson.errorString == "invalidsorts") {
				responseString = "ERROR: INVALID SORT";
			} else if (resJson.errorString == "invalidpage") {
				responseString = "ERROR: INVALID PAGE";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);
		} else if (resStatus == "401" || resStatus == 401) {
			if (resJson.errorString == "unauthorised") {
				responseString = "ERROR: USER NOT AUTHORISED";
			} else if (resJson.errorString == "userblocked") {
				responseString = "ERROR: USER BLOCKED";
			} else if (resJson.errorString == "outdatedappversion") {
				responseString = "ERROR: OUTDATED VERSION";
			} else {
				responseString = "ERROR: UNKNOWN";
			}

			return theCallback(resStatus, responseString, resJson);
		} else if (
			resStatus == "504" ||
			resStatus == 504 ||
			resStatus == "502" ||
			resStatus == 502
		) {
			responseString = "ERROR: TIMEOUT";
			return theCallback(resStatus, responseString, false);
		} else if (resStatus == "200" || resStatus == 200) {
			if (resJson.successString == "usersfound") {
			}

			return theCallback(resStatus, responseString, resJson);
		}
	};
	serverCall("GET", url, null, handleCallAPI, theJWTToken);
}

function getUserRecordsCount(queryParams, theCallback, theJWTToken) {
	const url = "/api/admin/userRecordsCount";
	const params = new URLSearchParams(queryParams).toString();
	serverCall(
		"GET",
		`${url}?${params}`,
		{},

		function (resStatus, resJson) {
			let responseString;
			if (resStatus == "200" || resStatus == 200) {
				if (resJson.message) {
					responseString = resJson.message;
				}
				return theCallback(resStatus, responseString, resJson);
			} else {
				return theCallback(resStatus, resJson.message, false);
			}
		},
		theJWTToken
	);
}

function setUser(theUser, theCallback, theJWTToken) {
	serverCall(
		"POST",
		"/api/setuser",
		theUser,
		function (resStatus, resJson) {
			let responseString;
			if (resStatus == "200" || resStatus == 200) {
				if (resJson.successString == "userupdated") {
					responseString = "SUCCESS: USER UPDATED";
				}
				return theCallback(resStatus, responseString, resJson);
			} else {
				let responseString = getResponseString(resStatus, resJson.errorString);
				return theCallback(resStatus, responseString, false);
			}
		},
		theJWTToken
	);
}

function deleteAvatarsAPI(theUser, theCallback, theJWTToken) {
	serverCall(
		"POST",
		"/api/deleteavatars",
		theUser,
		function (resStatus, resJson) {
			let responseString;
			if (resStatus == "200" || resStatus == 200) {
				if (resJson.successString == "avatarsdeleted") {
					responseString = "SUCCESS: AVATARS DELETED";
				}
				return theCallback(resStatus, responseString, resJson);
			} else {
				let responseString = getResponseString(resStatus, resJson.errorString);
				return theCallback(resStatus, responseString, false);
			}
		},
		theJWTToken
	);
}

function setJob(theJob, theCallback, theJWTToken) {
	serverCall(
		"POST",
		"/api/setjob",
		theJob,
		function (resStatus, resJson) {
			let responseString;

			if (resStatus == "200" || resStatus == 200) {
				if (resJson.successString == "jobupdated") {
					responseString = "SUCCESS: JOB UPDATED";
				}
				return theCallback(resStatus, responseString, resJson);
			} else {
				let responseString = getResponseString(resStatus, resJson.errorString);
				return theCallback(resStatus, responseString, false);
			}
		},
		theJWTToken
	);
}

function setQuote(theQuote, theCallback, theJWTToken) {
	serverCall(
		"POST",
		"/api/setquote",
		theQuote,
		function (resStatus, resJson) {
			let responseString;

			if (resStatus == "200" || resStatus == 200) {
				if (resJson.successString == "quoteupdated") {
					responseString = "SUCCESS: QUOTE UPDATED";
				}

				return theCallback(resStatus, responseString, resJson);
			} else {
				let responseString = getResponseString(resStatus, resJson.errorString);
				return theCallback(resStatus, responseString, false);
			}
		},
		theJWTToken
	);
}

function forcePaymentCompleted(theData, theCallback, theJWTToken) {
	serverCall(
		"POST",
		"/api/payments/paymentcompletedforce",
		theData,
		function (resStatus, resJson) {
			let responseString;

			if (resStatus == "200" || resStatus == 200) {
				if (resJson.successString == "quoteupdated") {
					responseString = "SUCCESS: QUOTE UPDATED";
				}

				return theCallback(resStatus, responseString, resJson);
			} else {
				let responseString = getResponseString(resStatus, resJson.errorString);
				return theCallback(resStatus, responseString, false);
			}
		},
		theJWTToken
	);
}

export {
	getHome,
	getUsers,
	setUser,
	deleteAvatarsAPI,
	setJob,
	setQuote,
	forcePaymentCompleted,
	getUsersClients,
	getUsersDrivers,
	getUserRecordsCount,
};
