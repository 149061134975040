import React from "react";
import $ from "jquery";
import _ from "lodash";
import { Link, NavLink } from "react-router-dom";
import moment from "moment-timezone";
// import InputRange from 'react-input-range';
import TopNav from "./TopNav";
import MavSelectComponent from "./MavSelectComponent";
import { setJob } from "../modules/Admin";
import { retrieveJob } from "../modules/JobAndQuoteManager";
import {
  modal,
  select,
  confirm,
  showSpinner,
  closeSpinner,
} from "../modules/Dialogs";
import { findAddress, addressAutoComplete } from "../modules/Geolocation";
import {
  sessionExpired,
  serverUrl,
  mavMode,
  userImagesFolder,
  jobImagesFolder,
  thumbSuffix,
  imageFormat,
  defaultAvatarImage,
  adminResumeJob,
  adminPauseJob,
  adminResumeNotice,
  adminPauseNotice,
  adminSaveChangesConfirm,
  adminReassignConfirm,
  adminJobAlreadyPaidStatus,
  adminJobAlreadyClosed,
  adminJobAlreadyPaid,
  adminJobAlreadyComplete,
  adminJobAlreadyPaidSetClosedNotice,
  adminJobAlreadyPaidSetCompleteNotice,
  adminJobReassignmentNotice,
  adminJobRefundApprovedNotice,
  adminJobOpenOther,
  adminJobClosed,
  bookV2ServiceTypeGetItems,
  bookV2ServiceTypeSendItems,
  bookV2ServiceTypeMove,
  bookV2ServiceTypeDispose,
  bookV2SingleItem,
  bookV2MultipleItems,
  bookV2WasteDisposal,
  bookV2TipRun,
  bookV2HouseClearance,
  bookV2DateAndTimeToday_heading,
  bookV2DateAndTimeTomorrow_heading,
  bookV2DateAndTimeThisweek_heading,
  bookV2DateAndTimeSpecificdate_heading,
  bookV2DateAndTimeNotsure_heading,
  jobDateInPast,
  jobDateTooFarAheadA,
  jobDateTooFarAheadB,
  defaultCommission,
  jobBookMaxFutureDays,
  defaultMessagesFilter,
} from "../config/GeneralConfig";
import { toScreen } from "../modules/Routing";
import {
  updateState,
  spoofedISOToMoment,
  momentToSpoofedISO,
  getDateAge,
  leftpad,
  calculatePricesWithCommission,
} from "../modules/Generic";
import AuditNotes from "./AuditNotes";
import PropTypes from "prop-types";
import withNavigate from "./withNavigate";
import ReactSlider from "react-slider";

class Job extends React.Component {
  constructor(props) {
    super(props);

    this.updateState = updateState.bind(this);

    this.updateNotes = this.updateNotes.bind(this);

    this.mavSelect = this.mavSelect.bind(this);
    this.approveRejectRefund = this.approveRejectRefund.bind(this);
    this.getJob = this.getJob.bind(this);
    this.getJob_done = this.getJob_done.bind(this);
    // this.callGetJobIfLoading = this.callGetJobIfLoading.bind(this);

    this.renderImages = this.renderImages.bind(this);
    this.renderQuotes = this.renderQuotes.bind(this);
    this.renderSelectedQuote = this.renderSelectedQuote.bind(this);
    //this.renderAvailableMavs = this.renderAvailableMavs.bind(this);
    this.saveJob = this.saveJob.bind(this);
    this.saveJob_done = this.saveJob_done.bind(this);
    this.findAddress = this.findAddress.bind(this);
    this.findAddress_done = this.findAddress_done.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.pauseToggle = this.pauseToggle.bind(this);
    this.discardChanges = this.discardChanges.bind(this);
    this.setTimeDateV1 = this.setTimeDateV1.bind(this);
    this.setTimeDate = this.setTimeDate.bind(this);
    // this.renderStripeRefs = this.renderStripeRefs.bind(this);
    this.bankTransferComplete = this.bankTransferComplete.bind(this);

    this.addressAutoComplete = this.addressAutoComplete.bind(this);
    this.addressAutoComplete_done = this.addressAutoComplete_done.bind(this);
    this.checkSuggestionMatch = this.checkSuggestionMatch.bind(this);
    this.renderMyAddresses = this.renderMyAddresses.bind(this);

    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleSliderComplete = this.handleSliderComplete.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.renderDays = this.renderDays.bind(this);

    this.selectMav = this.selectMav.bind(this);

    this.setLoader = this.setLoader.bind(this);

    this.state = {
      //curAddressFocus:false,
      loading: true,
      displayDate: "",
      time: "",
      date: "",
      sliderRange: {
        min: 7,
        max: 23,
      },
      creationDate: "",
      originalStatus: "",
      agreedQuotePrice: "",
      agreedCommission: "",
      // stripeRefs: [],
      //availableMavs: [],
      selectedMav: "",
      selectedQuote: "",
      selectedMavUsername: "",
      originalSelectedMav: "",
      originalRefundAuthorisation: false,
      theReview: {
        comment: false,
        stars: false,
      },
      mavPool: false,
      job: {
        _id: "",
        short_id: "",
        notes: [],
        paymentType: "",
        mavPayment: "",
        _creator: {
          short_id: "",
          accountInfo: {
            username: "",
            avatar: "",
          },
        },
        originAddress: {
          formattedAddress: "",
          googleValidated: "",
          placeId: "",
          streetNumber: "",
          streetName: "",
          postCode: "",
          townCityName: "",
          regionName: "",
          countryName: "United Kingdom",
        },
        destinationAddress: {
          formattedAddress: "",
          googleValidated: "",
          placeId: "",
          streetNumber: "",
          streetName: "",
          postCode: "",
          townCityName: "",
          regionName: "",
          countryName: "United Kingdom",
        },
        originDestinationDistance: {
          theDistance: "",
          theDuration: "",
        },
        jobSpec: {
          // LEGACY : V1 job spec
          itemType: "",
          itemName: "",
          itemDescription: "",
          additionalInfo: "",
          date: "",
          time: "",
          itemWeight: "",
          itemWeightExtra: "",
          // LEGACY

          specVersion: false,
          serviceType: false,
          serviceSubType: false,
          serviceDescription: "",
          dateAndTime: {
            type: false,
            days: {
              Monday: false,
              Tuesday: false,
              Wednesday: false,
              Thursday: false,
              Friday: false,
              Saturday: false,
              Sunday: false,
            },
            timeRange: {
              min: 0,
              max: 0,
            },
            standardDate: false,
          },
          serviceChecklist: {
            requirestwo: false,
            involvesstairs: false,
            nonepresentfrom: false,
            nonepresentto: false,
          },
          expectedPrice: false,

          images: [],
          deleteImages: [],
        },
        marketingDescription: "",
        commission: "",
        conversationOpen: false,
        status: "",
        refundRequested: false,
        refundAuthorisation: false,
        isPaused: false,
        quotes: [],
      },

      addressSuggestions: {
        originAddress: {
          one: {
            description: "A place",
          },
        },
        destinationAddress: {
          one: {
            description: "B place",
          },
        },
      },
      loaders: {
        originAddress: false,
        destinationAddress: false,
      },
    };
  }

  setLoader(loaderName, flag) {
    this.setState((prevState) => ({
      ...prevState,
      loaders: {
        ...prevState.loaders,
        [loaderName]: flag,
      },
    }));
  }

  setDatesTimes = () => {
    let newState = {};
    let newMom;

    if (
      this.state.job?.jobSpec?.dateAndTime?.type == "today" ||
      this.state.job?.jobSpec?.dateAndTime?.type == "thisweek"
    ) {
      if (this.state.job?.jobSpec?.dateAndTime?.standardDate) {
        newMom = spoofedISOToMoment(
          this.state.job?.jobSpec?.dateAndTime?.standardDate
        );
      } else {
        newMom = moment(new Date()).hour(12).minute(0).second(0).millisecond(0);
      }
    } else if (this.state.job?.jobSpec?.dateAndTime?.type == "tomorrow") {
      if (this.state.job?.jobSpec?.dateAndTime?.standardDate) {
        newMom = spoofedISOToMoment(
          this.state.job?.jobSpec?.dateAndTime?.standardDate
        );
      } else {
        newMom = moment(new Date())
          .add(1, "days")
          .hour(12)
          .minute(0)
          .second(0)
          .millisecond(0);
      }
    } else if (this.state.job?.jobSpec?.dateAndTime?.type == "specificdate") {
      if (this.state.job?.jobSpec?.dateAndTime?.standardDate) {
        newMom = spoofedISOToMoment(
          this.state.job?.jobSpec?.dateAndTime?.standardDate
        );
      } else {
        newMom = moment(new Date())
          .add(1, "days")
          .hour(9)
          .minute(0)
          .second(0)
          .millisecond(0);
      }
    } else if (this.state.job?.jobSpec?.dateAndTime?.type == "notsure") {
      newMom = moment(new Date()).hour(12).minute(0).second(0).millisecond(0);
    }

    if (
      this.state.job?.jobSpec?.dateAndTime?.type == "tomorrow" ||
      this.state.job?.jobSpec?.dateAndTime?.type == "thisweek"
    ) {
      if (
        !this.state.job?.jobSpec?.dateAndTime?.timeRange?.min ||
        !this.state.job?.jobSpec?.dateAndTime?.timeRange?.max
      ) {
        newState["job.jobSpec.dateAndTime.timeRange.min"] = 7;
        newState["job.jobSpec.dateAndTime.timeRange.max"] = 23;
      }

      newState["sliderRange.min"] = 7;
      newState["sliderRange.max"] = 23;
    } else if (this.state.job?.jobSpec?.dateAndTime?.type == "today") {
      let timeRangeMom = moment(new Date()).add(1, "hours");

      if (
        !this.state.job?.jobSpec?.dateAndTime?.timeRange?.min ||
        !this.state.job?.jobSpec?.dateAndTime?.timeRange?.max
      ) {
        newState["job.jobSpec.dateAndTime.timeRange.min"] = 7;
        newState["job.jobSpec.dateAndTime.timeRange.max"] = 23;
      } else {
        newState["job.jobSpec.dateAndTime.timeRange.min"] = parseInt(
          this.state.job?.jobSpec?.dateAndTime?.timeRange?.min
        );
        newState["job.jobSpec.dateAndTime.timeRange.max"] = parseInt(
          this.state.job?.jobSpec?.dateAndTime?.timeRange?.max
        );
      }

      newState["sliderRange.min"] = 7;
      newState["sliderRange.max"] = 23;
    }
    if (this.state.job?.jobSpec?.dateAndTime?.type != "notsure") {
      newState["job.jobSpec.dateAndTime.standardDate"] =
        momentToSpoofedISO(newMom);
      newState["date"] = newMom.format("YYYY-MM-DD");
      newState["time"] = newMom.format("HH:mm");
    }

    this.updateState(false, newState, () => {
      $(".input-range__track--active").after(
        '<div class="input-range__track--stripes"></div>'
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step1"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step2"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step3"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step4"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step5"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step6"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step7"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step8"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step9"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step10"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step11"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step12"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step13"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step14"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step15"
      );
      $(".input-range__track--stripes").removeClass(
        "input-range__track--stripes--step16"
      );
      $(".input-range__track--stripes").addClass(
        "input-range__track--stripes--step" +
        (this.state.sliderRange.max - this.state.sliderRange.min)
      );
    });
  };

  toggleButton(event, theTarget) {
    if (event) {
      event.preventDefault();
    }

    if (this.state.originalStatus === "closed" && name !== "job.status") {
      modal(adminJobAlreadyClosed);
    } else if (
      this.state.originalStatus === "complete" &&
      name !== "job.status"
    ) {
      modal(adminJobAlreadyComplete);
    } else if (
      this.state.originalStatus === "paid" &&
      name !== "job.status" &&
      name !== "selectedMav"
    ) {
      modal(adminJobAlreadyPaid);
      // STATUS CHANGES
    } else if (
      name === "job.status" &&
      this.state.originalStatus === "open" &&
      value !== "open" &&
      value !== "closed"
    ) {
      modal(adminJobOpenOther);
    } else if (
      name === "job.status" &&
      this.state.originalStatus === "closed" &&
      value !== "closed"
    ) {
      modal(adminJobClosed);
    } else if (
      name === "job.status" &&
      this.state.originalStatus == "complete" &&
      value !== "complete"
    ) {
      modal(adminJobAlreadyComplete);
    } else if (
      name === "job.status" &&
      this.state.originalStatus === "paid" &&
      value !== "paid" &&
      value !== "complete" &&
      value !== "closed"
    ) {
      modal(adminJobAlreadyPaidStatus);
    } else {
      this.updateState(
        false,
        { [theTarget]: _.get(this.state, theTarget) == true ? false : true },
        () => { }
      );
    }
  }

  renderDays(key) {
    const weekDays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    return (
      <div
        className={
          this.state.job?.jobSpec?.dateAndTime?.days[weekDays[key]]
            ? "c-days-select__day c-days-select__day--is-selected"
            : "c-days-select__day"
        }
        onClick={(e) =>
          this.toggleButton(e, "job.jobSpec.dateAndTime.days." + weekDays[key])
        }
        key={key}
      >
        {weekDays[key].charAt(0)}
      </div>
    );
  }

  handleSliderChange(value) {
    if (this.state.originalStatus === "closed" && name !== "job.status") {
      modal(adminJobAlreadyClosed);
    } else if (
      this.state.originalStatus === "complete" &&
      name !== "job.status"
    ) {
      modal(adminJobAlreadyComplete);
    } else if (
      this.state.originalStatus === "paid" &&
      name !== "job.status" &&
      name !== "selectedMav"
    ) {
      modal(adminJobAlreadyPaid);
      // STATUS CHANGES
    } else if (
      name === "job.status" &&
      this.state.originalStatus === "open" &&
      value !== "open" &&
      value !== "closed"
    ) {
      modal(adminJobOpenOther);
    } else if (
      name === "job.status" &&
      this.state.originalStatus === "closed" &&
      value !== "closed"
    ) {
      modal(adminJobClosed);
    } else if (
      name === "job.status" &&
      this.state.originalStatus == "complete" &&
      value !== "complete"
    ) {
      modal(adminJobAlreadyComplete);
    } else if (
      name === "job.status" &&
      this.state.originalStatus === "paid" &&
      value !== "paid" &&
      value !== "complete" &&
      value !== "closed"
    ) {
      modal(adminJobAlreadyPaidStatus);
    } else {
      this.updateState(
        false,
        {
          "job.jobSpec.dateAndTime.timeRange": { min: value[0], max: value[1] },
        },
        () => { }
      );
    }
  }

  handleSliderComplete(value, theCallback) { }

  updateNotes(newNotes, theCallback) {
    if (!_.isEqual(newNotes, this.state.job.notes)) {
      this.setState(
        {
          ...this.state,
          job: {
            ...this.state.job,
            notes: newNotes,
          },
        },
        theCallback
      );
    } else if (theCallback) {
      theCallback();
    }
  }

  discardChanges(event) {
    if (event) {
      event.preventDefault();
    }

    toScreen(false, this.props.navigate, "/jobs");
  }

  mavSelect(event) {
    if (event) {
      event.preventDefault();
    }

    this.refs.mavSelectComponent?.goMavSelect();
  }

  bankTransferComplete(event) {
    if (event) {
      event.preventDefault();
    }

    if (
      this.state.job.status == "complete" &&
      this.state.job.paymentType == "fullpayment" &&
      !this.state.job.mavPayment
    ) {
      this.setState(
        {
          ...this.state,

          job: {
            ...this.state.job,
            mavPayment: "banktransfercomplete",
          },
        },
        () => { }
      );
    }
  }

  approveRejectRefund(event, theDecision) {
    if (event) {
      event.preventDefault();
    }

    if (this.state.job.refundRequested) {
      if (theDecision == "approved") {
        modal(adminJobRefundApprovedNotice, "Refund Approval Notice");
      }
      this.setState({
        ...this.state,
        job: {
          ...this.state.job,
          refundAuthorisation: theDecision,
        },
      });
    }
  }

  selectMav(event, theMav, theSelectedMavUsername) {
    if (event) {
      event.preventDefault();
    }

    select(
      adminReassignConfirm,
      adminJobReassignmentNotice,
      "Cancel",
      "Yes",
      () => { },
      () => {
        this.setState(
          {
            ...this.state,
            selectedMav: theMav,
            selectedMavUsername: theSelectedMavUsername,
          },
          () => {
            this.refs.mavSelectComponent.mavSelect_close();
          }
        );
      }
    );
  }

  pauseToggle(event) {
    if (event) {
      event.preventDefault();
    }

    if (this.state.job.status == "open") {
      if (this.state.job.isPaused) {
        confirm(adminResumeJob, adminResumeNotice, "Cancel", "Resume", () => {
          this.setState({
            ...this.state,
            job: {
              ...this.state.job,
              isPaused: false,
            },
          });
        });
      } else {
        confirm(adminPauseJob, adminPauseNotice, "Cancel", "Pause", () => {
          this.setState({
            ...this.state,
            job: {
              ...this.state.job,
              isPaused: true,
            },
          });
        });
      }
    }
  }

  setTimeDateV1(event, theTime, theDate) {
    //LEGAY : V1 jobSpec

    if (event) {
      event.preventDefault();
    }

    var formattedDate;
    var formattedTime;
    var displayDate;
    var newMom;

    if (!theTime && !theDate) {
      formattedDate = "";
      formattedTime = "";
      displayDate = "";
    } else {
      if (theTime === "tomorrow" || theDate === "tomorrow") {
        var newDate = new Date().setHours(9, 0);
        newMom = moment(newDate).add(1, "days");
        formattedDate = newMom.format("DD/MM/YYYY").toLowerCase();
        displayDate = newMom.format("YYYY-MM-DD").toLowerCase();
        formattedTime = newMom.format("HH:mm");
      } else if (theDate === "asap") {
        formattedDate = "asap";
        displayDate = "";
      } else {
        if (!theTime) {
          theTime = this.state.job?.jobSpec?.time;
        }

        if (!theDate) {
          theDate = this.state.displayDate;
        }

        var dateString = theDate + " " + theTime;

        var newDate = new Date(dateString.replace(/-/g, "/"));
        newMom = moment(newDate);
        formattedDate = newMom.format("DD/MM/YYYY").toLowerCase();
        displayDate = newMom.format("YYYY-MM-DD").toLowerCase();
        formattedTime = newMom.format("HH:mm");
      }
      if (formattedDate != "") {
        if (getDateAge(formattedDate, formattedTime, "minutes") > 0) {
          modal(jobDateInPast);
          var newDate = new Date().setHours(9, 0);
          newMom = moment(newDate).add(1, "days");
          formattedDate = newMom.format("DD/MM/YYYY").toLowerCase();
          displayDate = newMom.format("YYYY-MM-DD").toLowerCase();
          formattedTime = newMom.format("HH:mm");
        } else if (
          getDateAge(formattedDate, formattedTime, "days") <
          jobBookMaxFutureDays
        ) {
          modal(
            jobDateTooFarAheadA +
            jobBookMaxFutureDays * -1 +
            jobDateTooFarAheadB
          );
          var newDate = new Date().setHours(9, 0);
          newMom = moment(newDate).add(1, "days");
          formattedDate = newMom.format("DD/MM/YYYY").toLowerCase();
          displayDate = newMom.format("YYYY-MM-DD").toLowerCase();
          formattedTime = newMom.format("HH:mm");
        }
      }
    }

    this.setState({
      ...this.state,
      displayDate: displayDate,
      job: {
        ...this.state.job,
        jobSpec: {
          ...this.state.job?.jobSpec,
          date: formattedDate,
          time: formattedTime,
        },
      },
    });
  }

  setTimeDate(event, theTarget) {
    if (event) {
      event.preventDefault();
    }

    const createStandardDate = () => {
      let newMom = moment(
        new Date(this.state.date.replace(/-/g, "/") + " " + this.state.time)
      );

      this.updateState(
        false,
        {
          [theTarget]: momentToSpoofedISO(newMom),
        },
        () => { }
      );
    };

    if (this.state.date && this.state.time) {
      let timeSplit = this.state.time.split(":");

      if (
        timeSplit[1] != "00" &&
        timeSplit[1] != "15" &&
        timeSplit[1] != "30" &&
        timeSplit[1] != "45"
      ) {
        this.updateState(
          false,
          {
            time:
              timeSplit[0] +
              ":" +
              leftpad(
                Math.floor((parseInt(timeSplit[1]) / 60) * 4) * 15,
                2,
                "0"
              ),
          },
          () => {
            createStandardDate();
          }
        );
      } else {
        createStandardDate();
      }
    }
  }

  getJob() {
    showSpinner();
    const theJob = {
      accountInfo: {
        username: this.props.userDetails.accountInfo.username,
        email: this.props.userDetails.accountInfo.email,
      },
      short_id: this.props.jobId ?? window.location.pathname?.split("/")[2],
    };

    retrieveJob(theJob, this.getJob_done, this.props.userDetails.jwttoken);
  }

  getJob_done(resStatus, responseString, resJson) {
    closeSpinner();
    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      modal(responseString);

      toScreen(false, this.props.navigate, "/jobs");
    } else if (resStatus == 200) {
      var theDisplayDate;
      var theDate;
      var theTime;

      if (
        !resJson.theJob?.jobSpec?.specVersion ||
        resJson.theJob?.jobSpec?.specVersion == 1
      ) {
        /*// LEGACY : V1 job spec */
        if (
          !resJson.theJob?.jobSpec?.date ||
          resJson.theJob?.jobSpec?.date == ""
        ) {
          theDisplayDate = "";
        } else {
          var newMom = moment.tz(
            resJson.theJob?.jobSpec?.date + " " + resJson.theJob?.jobSpec?.time,
            "Europe/London"
          );
          theDisplayDate = newMom.format("YYYY-MM-DD").toLowerCase();
        }
      } else {
        if (resJson.theJob?.jobSpec?.dateAndTime?.type != "notsure") {
          var newMom = spoofedISOToMoment(
            resJson.theJob?.jobSpec?.dateAndTime?.standardDate
          );
          theDate = newMom.format("YYYY-MM-DD").toLowerCase();
          theTime = newMom.format("HH:mm");
        }
      }

      var tmpAgreedQuotePrice = "";
      var tmpAgreedCommission = "";
      // var tmpStripeRefs = []; //selectedMav
      var tmpSelectedMav = "";
      var tmpSelectedQuote = "";
      var tmpSelectedMavUsername = "";
      var tmpOriginalSelectedMav = "";
      var tmpTheReview = "";

      var theQuoteId;

      _.forEach(resJson.theJob.payments, function (value) {
        if (value.paymentResponse && value.paymentResponse.id) {
          // tmpStripeRefs.push(value.paymentResponse.id);
          theQuoteId = value.quote;
        }
      });

      _.forEach(resJson.theJob.quotes, function (value) {
        if (
          theQuoteId == value._id ||
          (resJson?.theJob?.payments?.length === 0 &&
            resJson?.theJob?.payment &&
            resJson?.theJob?.payment?.quote == value._id)
        ) {
          var thePrices = calculatePricesWithCommission(
            false,
            value.quoteSpec?.price,
            resJson.theJob.commission || defaultCommission,
            2,
            true
          );
          tmpAgreedQuotePrice = thePrices.totalPrice;
          tmpAgreedCommission = thePrices.commission;
          tmpSelectedMav = value._creator.short_id;
          tmpSelectedQuote = value.short_id;
          tmpSelectedMavUsername = value._creator.accountInfo.username;
          tmpOriginalSelectedMav = value._creator.short_id;
          if (value.ratings && value.ratings[0] && value.ratings[0].stars) {
            tmpTheReview = {
              comment: value.ratings[0].comment,
              stars: value.ratings[0].stars,
            };
          }
        }
        // if (
        //   resJson?.theJob?.payments?.length === 0 &&
        //   resJson?.theJob?.payment
        // ) {
        //   var thePrices = calculatePricesWithCommission(
        //     false,
        //     value.quoteSpec?.price,
        //     resJson.theJob.commission || defaultCommission,
        //     2,
        //     true
        //   );
        //   tmpAgreedQuotePrice = thePrices.totalPrice;
        //   tmpAgreedCommission = thePrices.commission;
        //   tmpSelectedMav = value._creator.short_id;
        //   tmpSelectedQuote = value.short_id;
        //   tmpSelectedMavUsername = value._creator.accountInfo.username;
        //   tmpOriginalSelectedMav = value._creator.short_id;
        //   if (value.ratings && value.ratings[0] && value.ratings[0].stars) {
        //     tmpTheReview = {
        //       comment: value.ratings[0].comment,
        //       stars: value.ratings[0].stars,
        //     };
        //   }
        // }
      });

      this.setState(
        {
          ...this.state,
          loading: false,
          creationDate: resJson.theJob.creationDate,
          displayDate: theDisplayDate,
          time: theTime,
          date: theDate,
          originalStatus: resJson.theJob.status,
          selectedMav: tmpSelectedMav,
          selectedQuote: tmpSelectedQuote,
          selectedMavUsername: tmpSelectedMavUsername,
          originalSelectedMav: tmpOriginalSelectedMav,
          theReview: tmpTheReview,
          originalRefundAuthorisation: resJson.theJob.refundAuthorisation,
          agreedQuotePrice: tmpAgreedQuotePrice,
          agreedCommission: tmpAgreedCommission,
          stripeRefs:
            resJson.theJob?.payments[resJson.theJob?.payments?.length - 1]
              ?.paymentResponse?.id ??
            resJson.theJob?.payment?.paymentResponse?.paymentChargeId,
          mavPool: resJson.mavPool,
          //availableMavs: resJson.availableMavs,
          job: {
            ...resJson.theJob,
            jobSpec: {
              ...resJson.theJob?.jobSpec,
              deleteImages: [],
            },
            marketingDescription: resJson.theJob.marketingDescription,
            commission: resJson.theJob.commission,
          },
        },
        this.setDatesTimes
      );
    }
  }

  // callGetJobIfLoading() {
  // 	if (this.state.loading === true) {
  // 		this.getJob();
  // 	}
  // }

  saveJob(event) {
    if (event) {
      event.preventDefault();
    }

    confirm(false, adminSaveChangesConfirm, "Cancel", "Save", () => {
      showSpinner();

      const theJob = {
        _id: this.state.job._id,
        short_id: this.state.job.short_id,
        notes: this.state.job.notes,
        originAddress: { ...this.state.job?.originAddress },
        destinationAddress: { ...this.state.job?.destinationAddress },
        selectedMav: this.state.selectedMav,
        mavPayment: this.state.job.mavPayment,
        jobSpec: {
          itemType: this.state.job?.jobSpec?.itemType,
          itemName: this.state.job?.jobSpec?.itemName,
          itemDescription: this.state.job?.jobSpec?.itemDescription,
          additionalInfo: this.state.job?.jobSpec?.additionalInfo,
          date:
            this.state.job?.jobSpec?.date == ""
              ? "asap"
              : this.state.job?.jobSpec?.date,
          time: this.state.job.jobSpec?.time,
          itemWeight: this.state.job?.jobSpec.itemWeight,
          itemWeightExtra: this.state.job?.jobSpec?.itemWeightExtra,
          deleteImages: this.state.job?.jobSpec?.deleteImages,
          images: this.state.job?.jobSpec?.images,
        },
        marketingDescription: this.state.job.marketingDescription,
        status: this.state.job.status,
        refundRequested: this.state.job.refundRequested,
        refundAuthorisation: this.state.job.refundAuthorisation,
        isPaused: this.state.job.isPaused,
      };

      if (
        !this.state.job?.jobSpec?.specVersion ||
        this.state.job?.jobSpec?.specVersion == 1
      ) {
        theJob.jobSpec = {
          itemType: this.state.job?.jobSpec?.itemType,
          itemName: this.state.job?.jobSpec?.itemName,
          itemDescription: this.state.job?.jobSpec?.itemDescription,
          additionalInfo: this.state.job?.jobSpec?.additionalInfo,
          date:
            this.state.job?.jobSpec?.date == ""
              ? "asap"
              : this.state.job?.jobSpec?.date,
          time: this.state.job?.jobSpec?.time,
          itemWeight: this.state.job?.jobSpec?.itemWeight,
          itemWeightExtra: this.state.job?.jobSpec?.itemWeightExtra,
          deleteImages: this.state.job?.jobSpec?.deleteImages,
          images: this.state.job?.jobSpec?.images,
        };
      } else {
        theJob.jobSpec = {
          specVersion: this.state.job?.jobSpec?.specVersion,
          serviceType: this.state.job?.jobSpec?.serviceType,
          serviceSubType: this.state.job?.jobSpec?.serviceSubType,
          serviceDescription: this.state.job?.jobSpec?.serviceDescription,
          dateAndTime: this.state.job?.jobSpec?.dateAndTime,
          serviceChecklist: this.state.job?.jobSpec?.serviceChecklist,
          expectedPrice: this.state.job?.jobSpec?.expectedPrice,
          deleteImages: this.state.job?.jobSpec?.deleteImages,
          images: this.state.job?.jobSpec?.images,
        };
      }

      setJob(theJob, this.saveJob_done, this.props.userDetails.jwttoken);
    });
  }

  saveJob_done(resStatus, responseString, resJson) {
    closeSpinner();

    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      modal(responseString);
    } else if (resStatus == 200) {
      modal(responseString, false, () => {
        toScreen(false, this.props.navigate, "/jobs");
      });
    }
  }

  findAddress(event, addressTarget) {
    if (
      this.state.job[addressTarget].placeId &&
      this.state.job[addressTarget].placeId != "" &&
      this.state.originalStatus !== "closed" &&
      this.state.originalStatus !== "complete"
    ) {
      if (event) {
        event.preventDefault();
      }

      const theAddress = {
        addressTarget: addressTarget,
        theAddress: { ...this.state.job[addressTarget] },
      };

      findAddress(theAddress, this.findAddress_done);
    }
  }

  findAddress_done(resStatus, responseString, resJson) {
    if (resStatus == "400") {
    } else if (resStatus == 200) {
      //confirm(false, 'Did you mean ' + resJson.theAddress.formattedAddress + '?', 'No', 'Yes', () => {

      this.setState({
        ...this.state,
        job: {
          ...this.state.job,
          [resJson.addressTarget]: resJson.theAddress,
        },
      });

      //});
    }
  }

  renderImages(key) {
    const image = this.state.job?.jobSpec?.images[key];

    return (
      <div className="l-col-18" key={key}>
        <div className="c-avatar">
          <div className="c-avatar__pic-wrap">
            <img
              src={
                serverUrl +
                jobImagesFolder +
                "/" +
                this.state.job.short_id +
                "/" +
                image +
                thumbSuffix +
                imageFormat
              }
              alt={"image " + image}
              className="c-avatar__pic"
            />
          </div>
          <button value={image} onClick={(e) => this.deleteImage(e)}>
            Delete Image
          </button>
        </div>
      </div>
    );
  }

  addressAutoComplete(event, addressTarget) {
    event.preventDefault();
    this.setLoader(addressTarget, true);
    if (
      this.state.job[addressTarget].formattedAddress != "" &&
      (addressTarget === "originAddress" ||
        this.state.job[addressTarget].formattedAddress != "Nearest tip")
    ) {
      setTimeout(() => {
        var theAddress = {
          addressTarget: addressTarget,
          theAddress: { ...this.state.job[addressTarget] },
        };

        addressAutoComplete(theAddress, this.addressAutoComplete_done);
      }, 100);
    } else {
      this.setLoader(addressTarget, false);
    }
  }

  addressAutoComplete_done(resStatus, responseString, resJson) {
    if (resStatus == "400" || resStatus == 400) {
      this.setState({
        ...this.state,
        addressSuggestions: {
          originAddress: {},
          destinationAddress: {},
        },
      });
    } else if (resStatus == 200) {
      this.setState({
        ...this.state,
        addressSuggestions: {
          ...this.state.addressSuggestions,
          [resJson.addressTarget]: resJson.predictions,
        },
      });
    }
    this.setLoader(resJson.addressTarget, false);
  }

  checkSuggestionMatch(addressTarget, theCallback) {
    var foundMatch;

    _.forEach(this.state.addressSuggestions[addressTarget], (value, key) => {
      if (
        value.description === this.state.job[addressTarget].formattedAddress
      ) {
        foundMatch = value;
      }
    });

    if (foundMatch) {
      return this.setState(
        {
          ...this.state,
          job: {
            ...this.state.job,
            originDestinationDistance: {
              theDistance: "",
              theDuration: "",
            },
            [addressTarget]: {
              ...this.state.job[addressTarget],
              formattedAddress: foundMatch.description,
              googleValidated: "",
              placeId: foundMatch.place_id,
              streetNumber: "",
              streetName: "",
              postCode: "",
              townCityName: "",
              regionName: "",
              countryName: "United Kingdom",
            },
          },
          addressSuggestions: {
            originAddress: {},
            destinationAddress: {},
          },
        },
        theCallback
      );
    } else if (theCallback) {
      return theCallback();
    }
  }

  renderQuotes(key) {
    const quote = this.state.job.quotes[key];
    var itemDescriptionShort = "";

    if (!quote.quoteSpec.itemDescription) {
      itemDescriptionShort = "";
    } else if (quote.quoteSpec.itemDescription > 18) {
      itemDescriptionShort =
        quote.quoteSpec.itemDescription.substring(0, 15) + "...";
    } else {
      itemDescriptionShort = quote.quoteSpec.itemDescription;
    }
    if (quote._creator && quote._creator.short_id != this.state.selectedMav) {
      return (
        <div className="c-list-entry" key={key}>
          <div
            className={
              quote.status == "closed" && quote.jobChanged
                ? "c-list-entry__primary u-color-grey-dark u-line-through"
                : this.state.selectedMav && this.state.selectedMav != ""
                  ? "c-list-entry__primary u-color-grey-dark"
                  : "c-list-entry__primary"
            }
          >
            <b>{quote._creator.accountInfo.username}</b> |{" "}
            {quote.quoteSpec?.price} |{" "}
            <b>
              {quote.archived && quote.status == "open"
                ? "archived"
                : quote.status}
              {quote.declined && quote.declined.reasonString
                ? " (Declined)"
                : null}
            </b>{" "}
            | {itemDescriptionShort}
          </div>
          <button
            type="button"
            className="c-btn c-btn--teal c-btn--small"
            onClick={(e) =>
              toScreen(e, this.props.navigate, "/quote/" + quote.short_id)
            }
          >
            view
          </button>
        </div>
      );
    }
  }

  renderSelectedQuote() {
    var quote;
    var itemDescriptionShort = "";

    _.forEach(this.state.job.quotes, (value, key) => {
      if (value.short_id == this.state.selectedQuote) {
        quote = value;
      }
    });

    if (!quote.quoteSpec.itemDescription) {
      itemDescriptionShort = "";
    } else if (quote.quoteSpec.itemDescription > 18) {
      itemDescriptionShort =
        quote.quoteSpec.itemDescription.substring(0, 15) + "...";
    } else {
      itemDescriptionShort = quote.quoteSpec.itemDescription;
    }

    if (quote) {
      return (
        <div className="c-list-entry">
          <div className="c-list-entry__primary">
            <b>{quote._creator.accountInfo.username}</b>
            {quote._creator.accountInfo.baseLocation &&
              quote._creator.accountInfo.baseLocation.homeaddress &&
              quote._creator.accountInfo.baseLocation.homeaddress.formattedAddress
              ? " | " +
              quote._creator.accountInfo.baseLocation.homeaddress.formattedAddress.split(
                ", United Kingdom"
              )[0]
              : ""}{" "}
            | &pound;{quote.quoteSpec?.price} |{" "}
            <b>
              {quote.archived && quote.status == "open"
                ? "archived"
                : quote.status}
              {quote.declined && quote.declined.reasonString
                ? " (Declined)"
                : null}
            </b>{" "}
            | {quote.creationDate} at {quote.creationTime}
          </div>
          <button
            type="button"
            className="c-btn c-btn--teal c-btn--small"
            onClick={(e) =>
              toScreen(e, this.props.navigate, "/quote/" + quote.short_id)
            }
          >
            view
          </button>
        </div>
      );
    }
  }

  // renderAvailableMavs(key) {

  // 	const mav = this.state.availableMavs[key];

  // 	return (
  // 		<option value={mav.short_id} key={key}>{mav.accountInfo.username}</option>
  // 	)
  // }

  renderMyAddresses(key, addressTarget) {
    const address = this.state.addressSuggestions[addressTarget][key];

    return <option value={address.description} key={key} />;
  }

  handleInputChange(event, theCallback) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var oldValue = false;

    const inputChangeCallback = () => {
      if (name === "job.jobSpec.dateAndTime.type") {
        if (oldValue != value) {
          this.setDatesTimes();
        }
      } else if (name === "job.status") {
        if (this.state.originalStatus == "paid" && value === "closed") {
          modal(adminJobAlreadyPaidSetClosedNotice, "Refund Notice");
        }

        if (this.state.originalStatus == "paid" && value === "complete") {
          modal(adminJobAlreadyPaidSetCompleteNotice, "Payment Notice");
        }

        if (theCallback) {
          theCallback();
        }
      } else if (name == "selectedMav") {
        if (value != this.state.originalSelectedMav) {
          modal(adminJobReassignmentNotice, "Reassignment Notice");
        }

        if (theCallback) {
          theCallback();
        }
      } else if (name === "job.jobSpec.itemWeight" && oldValue != value) {
        this.setState(
          {
            ...this.state,
            job: {
              ...this.state.job,
              jobSpec: {
                ...this.state.job?.jobSpec,
                itemWeightExtra: "",
              },
            },
          },
          theCallback
        );
      } else if (name === "job.jobSpec.serviceType" && oldValue != value) {
        this.setState(
          {
            ...this.state,
            job: {
              ...this.state.job,
              jobSpec: {
                ...this.state.job?.jobSpec,
                serviceSubType: false,
              },
            },
          },
          theCallback
        );
      } else if (name === "job.originAddress.formattedAddress") {
        this.checkSuggestionMatch("originAddress", theCallback);
      } else if (name === "job.destinationAddress.formattedAddress") {
        this.checkSuggestionMatch("destinationAddress", theCallback);
      } else {
        if (theCallback) {
          theCallback();
        }
      }
    };

    // CONTENT CHANGES
    if (this.state.originalStatus === "closed" && name !== "job.status") {
      modal(adminJobAlreadyClosed);
    } else if (
      this.state.originalStatus === "complete" &&
      name !== "job.status" &&
      name !== "job.marketingDescription"
    ) {
      modal(adminJobAlreadyComplete);
    } else if (
      this.state.originalStatus === "paid" &&
      name !== "job.status" &&
      name !== "selectedMav"
    ) {
      modal(adminJobAlreadyPaid);
      // STATUS CHANGES
    } else if (
      name === "job.status" &&
      this.state.originalStatus === "open" &&
      value !== "open" &&
      value !== "closed"
    ) {
      modal(adminJobOpenOther);
    } else if (
      name === "job.status" &&
      this.state.originalStatus === "closed" &&
      value !== "closed"
    ) {
      modal(adminJobClosed);
    } else if (
      name === "job.status" &&
      this.state.originalStatus == "complete" &&
      value !== "complete"
    ) {
      modal(adminJobAlreadyComplete);
    } else if (
      name === "job.status" &&
      this.state.originalStatus === "paid" &&
      value !== "paid" &&
      value !== "complete" &&
      value !== "closed"
    ) {
      modal(adminJobAlreadyPaidStatus);
    } else if (name.split(".").length == 1) {
      oldValue = this.state[name];
      //select(theHeading, theMessage, theChoiceALabel, theChoiceBLabel, theChoiceACallback, theChoiceBCallback)
      // if (name == 'selectedMav' && oldValue != value) {

      // 	confirm(false, adminReassignConfirm, 'Cancel', 'Yes', () => {

      // 		this.setState({
      // 			...this.state,
      // 			[name]: value
      // 		}, inputChangeCallback);

      // 	});

      // } else {
      this.setState(
        {
          [name]: value,
        },
        () => {
          // if (name == 'selectedMav' && oldValue != value) {

          // 	select(false, adminReassignConfirm, 'Cancel', 'Yes', () => {

          // 		this.setState({
          // 			...this.state,
          // 			[name]: oldValue
          // 		});

          // 	}, inputChangeCallback);

          // } else {
          inputChangeCallback;
          //}
        }
      );
      //}
    } else if (name.split(".").length == 2) {
      oldValue = this.state[name.split(".")[0]][name.split(".")[1]];
      this.setState(
        {
          ...this.state,
          [name.split(".")[0]]: {
            ...this.state[name.split(".")[0]],
            [name.split(".")[1]]: value,
          },
        },
        inputChangeCallback
      );
    } else if (name.split(".").length == 3) {
      if (
        (name.split(".")[1] == "originAddress" ||
          name.split(".")[1] == "destinationAddress") &&
        this.state[name.split(".")[0]][name.split(".")[1]][
        name.split(".")[2]
        ] != value
      ) {
        if (name.split(".")[2] == "formattedAddress") {
          this.setState(
            {
              ...this.state,
              [name.split(".")[0]]: {
                ...this.state[name.split(".")[0]],
                originDestinationDistance: {
                  theDistance: "",
                  theDuration: "",
                },
                [name.split(".")[1]]: {
                  ...this.state[name.split(".")[1]],
                  [name.split(".")[2]]: value,
                  googleValidated: "",
                  placeId: "",
                  streetNumber: "",
                  streetName: "",
                  postCode: "",
                  townCityName: "",
                  regionName: "",
                  countryName: "United Kingdom",
                },
              },
            },
            inputChangeCallback
          );
        } else {
          this.setState(
            {
              ...this.state,
              [name.split(".")[0]]: {
                ...this.state[name.split(".")[0]],
                originDestinationDistance: {
                  theDistance: "",
                  theDuration: "",
                },
                [name.split(".")[1]]: {
                  ...this.state[name.split(".")[1]],
                  [name.split(".")[2]]: value,
                  googleValidated: "",
                  placeId: "",
                  formattedAddress: "",
                },
              },
            },
            inputChangeCallback
          );
        }
      } else {
        oldValue =
          this.state[name.split(".")[0]][name.split(".")[1]][
          name.split(".")[2]
          ];
        this.setState({
          [name.split(".")[0]]: {
            ...this.state[name.split(".")[0]],
            [name.split(".")[1]]: {
              ...this.state[name.split(".")[0]][name.split(".")[1]],
              [name.split(".")[2]]: value,
            },
          },
        });
      }
    } else if (name.split(".").length == 4) {
      oldValue =
        this.state[name.split(".")[0]][name.split(".")[1]][name.split(".")[2]];
      this.setState(
        {
          [name.split(".")[0]]: {
            ...this.state[name.split(".")[0]],
            [name.split(".")[1]]: {
              ...this.state[name.split(".")[0]][name.split(".")[1]],
              [name.split(".")[2]]: {
                ...this.state[name.split(".")[0]][name.split(".")[1]][
                name.split(".")[2]
                ],
                [name.split(".")[3]]: value,
              },
            },
          },
        },
        inputChangeCallback
      );
    }
  }

  handleInputBlur(event, theCallback) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (
      name === "job.originAddress.formattedAddress" ||
      name === "job.destinationAddress.formattedAddress"
    ) {
      this.setState(
        {
          ...this.state,
          addressSuggestions: {
            originAddress: {},
            destinationAddress: {},
          },
        },
        theCallback
      );
    } else if (theCallback) {
      return theCallback();
    }
  }

  deleteImage(event) {
    if (event) {
      event.preventDefault();
    }

    if (this.state.originalStatus === "closed") {
      modal(adminJobAlreadyClosed);
    } else if (this.state.originalStatus === "complete") {
      modal(adminJobAlreadyComplete);
    } else if (this.state.originalStatus === "paid") {
      modal(adminJobAlreadyPaid);
    } else {
      var newDeleteImages = this.state.job?.jobSpec?.deleteImages.slice(0);
      var newImages = this.state.job?.jobSpec?.images.slice(0);

      if (newDeleteImages.indexOf(parseInt(event.target.value)) == -1) {
        newDeleteImages.push(parseInt(event.target.value));
      }

      if (newImages.indexOf(parseInt(event.target.value)) != -1) {
        newImages.splice(newImages.indexOf(parseInt(event.target.value)), 1);
      }

      this.setState({
        job: {
          ...this.state.job,
          jobSpec: {
            ...this.state.job?.jobSpec,
            deleteImages: newDeleteImages,
            images: newImages,
          },
        },
      });
    }
  }

  // renderStripeRefs(key) {
  //   const stripeRef = this.state.stripeRefs[key];
  //   return (
  //     <div className="c-input-wide" key={key}>
  //       <label className="c-input-wide__label">Stripe payment ref:</label>
  //       <span key={key}>
  //         <a
  //           href={
  //             (mavMode == "dev"
  //               ? "https://dashboard.stripe.com/test/payments/"
  //               : "https://dashboard.stripe.com/payments/") + stripeRef
  //           }
  //           target="_blank"
  //         >
  //           {stripeRef}
  //         </a>
  //       </span>
  //     </div>
  //   );
  // }

  componentDidMount() {
    this.getJob();
    // this.timer = setTimeout(() => {
    // 	this.callGetJobIfLoading();
    // }, 5000);
  }
  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    if (this.state.loading === true) {
      return (
        <div className="l-base-layout__content">
          <h1>Admin / Job / Details</h1>

          <div className="c-hr"></div>

          <div className="c-subheader-info-panel">
            <div className="c-subheader-info-panel__primary u-uppercase">
              ID: {this.state.job.short_id}
            </div>
          </div>
          <h1>Loading ...</h1>
        </div>
      );
    } else {
      return (
        <div className="l-base-layout__content">
          <h1>Admin / Job / Details</h1>

          <div className="c-hr"></div>

          <div className="c-subheader-info-panel">
            <div className="c-subheader-info-panel__primary u-uppercase">
              ID: {this.state.job.short_id}
            </div>
          </div>

          <div className="c-hr"></div>

          <div className="l-row">
            <div className="l-col-18">
              <div className="c-avatar">
                <div className="c-avatar__pic-wrap">
                  <img
                    src={
                      /*this.state.job?.jobSpec?.images?.length>0 ? serverUrl+jobImagesFolder+'/'+this.state.job.short_id+'/'+this.state.job?.jobSpec?.images[0]+thumbSuffix+imageFormat : */ this
                        .state.job._creator.accountInfo.avatar &&
                        this.state.job._creator.accountInfo.avatar != ""
                        ? serverUrl +
                        userImagesFolder +
                        "/" +
                        this.state.job._creator.short_id +
                        "/" +
                        this.state.job._creator.accountInfo.avatar +
                        imageFormat
                        : defaultAvatarImage
                    }
                    alt="job image"
                    className="c-avatar__pic"
                    onError={(e) => {
                      e.target.src = defaultAvatarImage;
                    }}
                  />
                </div>
                {/*this.state.job?.jobSpec?.images?.length>0 ? <button value={this.state.job?.jobSpec?.images[0]} onClick={(e)=>this.deleteImage(e)}>Delete Image</button> : ''*/}
              </div>
            </div>

            <div className="l-col-30">
              Created by :{" "}
              <button
                onClick={(e) =>
                  toScreen(
                    e,
                    this.props.navigate,
                    "/client/" + this.state.job._creator.short_id
                  )
                }
              >
                <span className="u-color-teal u-font-size-16 u-font-bold">
                  {this.state.job._creator.accountInfo.username}
                </span>
              </button>
              <br />
              On: {this.state.creationDate}
            </div>
            <div className="l-col-11">
              {this.state.job.status == "open" ? (
                <button
                  className="c-btn c-btn--small c-btn--full-width u-margin-bottom-xs  c-btn--light-grey"
                  onClick={(e) => this.pauseToggle(e)}
                >
                  <span
                    className={
                      this.state.job.isPaused
                        ? "u-before-icon-play u-color-green"
                        : "u-before-icon-pause u-color-red"
                    }
                  >
                    &nbsp;{this.state.job.isPaused ? " resume" : " pause"}
                  </span>
                </button>
              ) : null}
            </div>
            <div className="l-col-41">
              <button
                className="c-btn c-btn--small c-btn--full-width u-margin-bottom-xs  c-btn--red"
                onClick={(e) => this.saveJob(e)}
              >
                Save Changes
              </button>
              <button
                className="c-btn c-btn--small c-btn--full-width  c-btn--grey"
                onClick={(e) => this.discardChanges(e)}
              >
                Discard Changes
              </button>
              <button
                className="c-btn c-btn--small c-btn--full-width  c-btn--green"
                onClick={(e) =>
                  toScreen(
                    e,
                    this.props.navigate,
                    "/message/" + this.state.job._creator.short_id,
                    false,
                    {
                      receiverId: this.state.job?._creator?._id,
                      receiverShortId: this.state.job._creator.short_id,
                      receiverType: "user",
                      userShortId1: "admin",
                      userShortId2: this.state.job._creator.short_id,
                      username2:
                        this.state.job?._creator?.accountInfo?.username,
                      selectedPage: window.history.state.usr?.selectedPage ?? 1,
                      selectedFilter:
                        window.history.state.usr?.selectedFilter ??
                        defaultMessagesFilter,
                      previousPath:
                        window.history.state.usr?.previousPath ??
                        window.location.pathname,
                    }
                  )
                }
              >
                Contact Client
              </button>
            </div>
          </div>

          <div className="c-hr"></div>

          <div className="l-row">
            <div className="l-col-100">
              <div className="c-checkbox-radio">
                <input
                  type="radio"
                  id="open"
                  name="job.status"
                  value="open"
                  onChange={this.handleInputChange}
                  checked={this.state.job.status === "open"}
                />
                <label htmlFor="open">Open</label>
              </div>
              <div className="c-checkbox-radio">
                <input
                  type="radio"
                  id="closed"
                  name="job.status"
                  value="closed"
                  onChange={this.handleInputChange}
                  checked={this.state.job.status === "closed"}
                />
                <label htmlFor="closed">Closed</label>
              </div>
              <div className="c-checkbox-radio">
                <input
                  type="radio"
                  id="paid"
                  name="job.status"
                  value="paid"
                  onChange={this.handleInputChange}
                  checked={this.state.job.status === "paid"}
                />
                <label htmlFor="paid">Paid</label>
              </div>
              <div
                className={
                  this.state.job.refundRequested ||
                    (this.state.job.status == "complete" &&
                      this.state.job.paymentType == "fullpayment")
                    ? "c-checkbox-radio c-checkbox-radio"
                    : "c-checkbox-radio c-checkbox-radio--no-right-border"
                }
              >
                <input
                  type="radio"
                  id="complete"
                  name="job.status"
                  value="complete"
                  onChange={this.handleInputChange}
                  checked={this.state.job.status === "complete"}
                />
                <label htmlFor="complete">Complete</label>
              </div>
              {this.state.job.refundRequested &&
                (!this.state.job.refundAuthorisation ||
                  this.state.job.refundAuthorisation == "pending") ? (
                <div className="c-checkbox-radio-notice">
                  <span className="c-checkbox-radio-notice__alert u-before-icon-exclamation text-yellow">
                    &nbsp;Refund requested
                  </span>
                  <button
                    type="button"
                    className="c-btn c-btn--teal c-btn--small"
                    onClick={(e) => this.approveRejectRefund(e, "rejected")}
                  >
                    Reject
                  </button>
                  <button
                    type="button"
                    className="c-btn c-btn--red c-btn--small"
                    onClick={(e) => this.approveRejectRefund(e, "approved")}
                  >
                    Approve
                  </button>
                </div>
              ) : this.state.job.refundRequested &&
                this.state.job.refundAuthorisation == "approved" ? (
                <div className="c-checkbox-radio-notice">
                  <span className="c-checkbox-radio-notice u-before-icon-check">
                    &nbsp;Refund Approved
                  </span>
                </div>
              ) : this.state.job.refundRequested &&
                this.state.job.refundAuthorisation == "rejected" ? (
                <div className="c-checkbox-radio-notice">
                  <span className="c-checkbox-radio-notice u-before-icon-cancel">
                    &nbsp;Refund Rejected
                  </span>
                </div>
              ) : null}

              {this.state.job.status == "complete" &&
                this.state.job.paymentType == "fullpayment" &&
                !this.state.job.mavPayment ? (
                <div className="c-checkbox-radio-notice">
                  <span className="c-checkbox-radio-notice__alert u-before-icon-exclamation">
                    &nbsp;Bank transfer required{" "}
                  </span>
                  <button
                    type="button"
                    className="c-btn c-btn--teal c-btn--small"
                    onClick={(e) => this.bankTransferComplete(e)}
                  >
                    Complete
                  </button>
                </div>
              ) : null}
            </div>
          </div>

          <form action="">
            <div className="c-hr u-margin-bottom-s"></div>
            <div className="l-row">
              <div className="l-col-66">
                <div className="c-input-wide">
                  <label className="c-input-wide__label">Driver :</label>
                  {this.state.selectedMav && this.state.selectedMav != "" ? (
                    this.state.job.status == "paid" ? (
                      <span className="c-input-wide__copy">
                        <button
                          onClick={(e) =>
                            toScreen(
                              e,
                              this.props.navigate,
                              "/mav/" + this.state.selectedMav
                            )
                          }
                        >
                          <span className="u-color-teal u-font-size-16 u-font-bold">
                            {this.state.selectedMavUsername} |{" "}
                            {this.state.selectedMav}
                          </span>
                        </button>
                        <button
                          type="button"
                          className="c-btn c-btn--teal c-btn--small"
                          onClick={(e) => this.mavSelect(e)}
                        >
                          Reassign
                        </button>
                      </span>
                    ) : (
                      <button
                        onClick={(e) =>
                          toScreen(
                            e,
                            this.props.navigate,
                            "/mav/" + this.state.selectedMav
                          )
                        }
                      >
                        <span className="u-color-teal u-font-size-16 u-font-bold text-capitalize">
                          {this.state.selectedMavUsername} |{" "}
                          <span className="u-uppercase">
                            {this.state.selectedMav}
                          </span>
                        </span>
                      </button>
                    )
                  ) : (
                    <span className="c-input-wide__copy u-margin-bottom-xs">
                      <b>'N/A'</b>
                    </span>
                  )}
                </div>

                {this.state.theReview.stars ? (
                  <div className="c-hr u-margin-bottom-s u-margin-top-s"></div>
                ) : null}

                {this.state.theReview.stars ? (
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">
                      Client Review :
                    </label>

                    <span className="c-input-wide__copy">
                      <div
                        className="c-rating c-rating--list"
                        onClick={(e) =>
                          toScreen(
                            e,
                            this.props.navigate,
                            "/mav/" + this.state.selectedMav + "/reviews"
                          )
                        }
                      >
                        <fieldset className="c-rating__wrap">
                          <input
                            tabIndex="-1"
                            className="c-rating__input"
                            type="radio"
                            name="stars1"
                            value="5"
                            checked={this.state.theReview.stars === 5}
                            readOnly
                          />
                          <label className="c-rating__label-read-only-small">
                            <span className="u-visually-hidden">5 stars</span>
                          </label>
                          <input
                            tabIndex="-1"
                            className="c-rating__input"
                            type="radio"
                            name="stars2"
                            value="4"
                            checked={this.state.theReview.stars === 4}
                            readOnly
                          />
                          <label className="c-rating__label-read-only-small">
                            <span className="u-visually-hidden">4 stars</span>
                          </label>
                          <input
                            tabIndex="-1"
                            className="c-rating__input"
                            type="radio"
                            name="stars3"
                            value="3"
                            checked={this.state.theReview.stars === 3}
                            readOnly
                          />
                          <label className="c-rating__label-read-only-small">
                            <span className="u-visually-hidden">3 stars</span>
                          </label>
                          <input
                            tabIndex="-1"
                            className="c-rating__input"
                            type="radio"
                            name="stars4"
                            value="2"
                            checked={this.state.theReview.stars === 2}
                            readOnly
                          />
                          <label className="c-rating__label-read-only-small">
                            <span className="u-visually-hidden">2 stars</span>
                          </label>
                          <input
                            tabIndex="-1"
                            className="c-rating__input"
                            type="radio"
                            name="stars5"
                            value="1"
                            checked={this.state.theReview.stars === 1}
                            readOnly
                          />
                          <label className="c-rating__label-read-only-small">
                            <span className="u-visually-hidden">1 star</span>
                          </label>
                        </fieldset>
                      </div>

                      {this.state.theReview.comment ? (
                        <div
                          className="c-message-bubble u-margin-bottom-0 u-margin-top-s"
                          onClick={(e) =>
                            toScreen(
                              e,
                              this.props.navigate,
                              "/mav/" + this.state.selectedMav + "/reviews"
                            )
                          }
                        >
                          <div className="c-message-bubble__copy">
                            "{this.state.theReview.comment}"
                          </div>
                        </div>
                      ) : null}
                    </span>
                  </div>
                ) : null}

                <div className="c-hr u-margin-bottom-s u-margin-top-s"></div>

                {/*// LEGACY : V1 job spec */}

                {!this.state.job?.jobSpec?.specVersion ||
                  this.state.job?.jobSpec?.specVersion == 1 ? (
                  <div className="c-input-wide">
                    <div className="c-input-wide__label">Job Type:</div>
                    <select
                      className="c-input-wide__input"
                      value={this.state.job?.jobSpec?.itemType}
                      onChange={this.handleInputChange}
                      name="job.jobSpec.itemType"
                    >
                      <option value="">Please select</option>
                      <option value="singleitem">Single item</option>
                      <option value="collection">Collection</option>
                      <option value="multipleitems">Multiple items</option>
                      <option value="wastedisposal">
                        Waste disposal / tip run
                      </option>
                      <option value="removals">Removals</option>
                      <option value="houseclearance">House Clearance</option>
                    </select>
                  </div>
                ) : null}

                {/*// LEGACY */}

                {/* {Object.keys(this.state.stripeRefs).map(this.renderStripeRefs)} */}

                {this.state.stripeRefs && (
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">
                      Stripe payment ref:
                    </label>
                    <span className="c-input-wide__copy">
                      <a
                        href={
                          (mavMode == "dev"
                            ? "https://dashboard.stripe.com/test/payments/"
                            : "https://dashboard.stripe.com/payments/") +
                          this.state.stripeRefs
                        }
                        target="_blank"
                      >
                        {this.state.stripeRefs}
                      </a>
                    </span>
                  </div>
                )}

                <div className="c-input-wide">
                  <label className="c-input-wide__label">Payment Type :</label>
                  <span className="c-input-wide__copy">
                    <b>
                      {(this.state.job.status == "paid" ||
                        this.state.job.status == "complete") &&
                        this.state.job.paymentType === "depositpluscash"
                        ? "Card deposit with direct payment"
                        : (this.state.job.status == "paid" ||
                          this.state.job.status == "complete") &&
                          this.state.job.paymentType === "cashless"
                          ? "Card full payment"
                          : (this.state.job.status == "paid" ||
                            this.state.job.status == "complete") &&
                            this.state.job.paymentType === "fullpayment"
                            ? "Full Payment"
                            : "-"}
                    </b>
                  </span>
                </div>

                <div className="c-input-wide">
                  <label className="c-input-wide__label">
                    MAV payment status :
                  </label>
                  <span className="c-input-wide__copy">
                    <b>
                      {this.state.job.mavPayment === "cashpaid"
                        ? "Payment received"
                        : this.state.job.mavPayment === "stripetransfercomplete"
                          ? "Stripe transfer complete"
                          : this.state.job.mavPayment === "banktransfercomplete"
                            ? "Bank transfer complete"
                            : "-"}
                    </b>
                  </span>
                </div>

                {this.state.job?.jobSpec?.specVersion ? (
                  <div className="c-input-wide">
                    <div className="c-input-wide__label">Service Type:</div>
                    <select
                      className="c-input-wide__input"
                      value={this.state.job?.jobSpec?.serviceType}
                      onChange={this.handleInputChange}
                      name="job.jobSpec.serviceType"
                    >
                      <option value="">Please select</option>
                      <option value="senditems">
                        {bookV2ServiceTypeSendItems}
                      </option>
                      <option value="getitems">
                        {bookV2ServiceTypeGetItems}
                      </option>
                      <option value="housemove">{bookV2ServiceTypeMove}</option>
                      <option value="dispose">
                        {bookV2ServiceTypeDispose}
                      </option>
                    </select>
                  </div>
                ) : null}

                {this.state.job?.jobSpec?.specVersion &&
                  (this.state.job?.jobSpec?.serviceType == "senditems" ||
                    this.state.job?.jobSpec?.serviceType == "getitems") ? (
                  <div className="c-input-wide">
                    <div className="c-input-wide__label">Service Sub Type:</div>
                    <select
                      className="c-input-wide__input"
                      value={this.state.job?.jobSpec?.serviceSubType}
                      onChange={this.handleInputChange}
                      name="job.jobSpec.serviceSubType"
                    >
                      <option value="">Please select</option>
                      <option value="singleitem">{bookV2SingleItem}</option>
                      <option value="multipleitems">
                        {bookV2MultipleItems}
                      </option>
                    </select>
                  </div>
                ) : null}

                {this.state.job?.jobSpec?.specVersion &&
                  this.state.job?.jobSpec?.serviceType == "dispose" ? (
                  <div className="c-input-wide">
                    <div className="c-input-wide__label">Service Sub Type:</div>
                    <select
                      className="c-input-wide__input"
                      value={this.state.job?.jobSpec?.serviceSubType}
                      onChange={this.handleInputChange}
                      name="job.jobSpec.serviceSubType"
                    >
                      <option value="">Please select</option>
                      <option value="wastedisposal">
                        {bookV2WasteDisposal}
                      </option>
                      <option value="tiprun">{bookV2TipRun}</option>
                      <option value="houseclearance">
                        {bookV2HouseClearance}
                      </option>
                    </select>
                  </div>
                ) : null}

                {this.state.job?.jobSpec?.specVersion ? (
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">Description:</label>
                    <textarea
                      rows="2"
                      type="text"
                      value={this.state.job?.jobSpec?.serviceDescription}
                      onChange={this.handleInputChange}
                      name="job.jobSpec.serviceDescription"
                      placeholder="Description"
                      className="c-input-wide__input"
                    />
                  </div>
                ) : null}

                {/*// LEGACY : V1 job spec */}

                {!this.state.job?.jobSpec?.specVersion ||
                  this.state.job?.jobSpec?.specVersion == 1 ? (
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">Name:</label>
                    <input
                      type="text"
                      value={this.state.job?.jobSpec?.itemName}
                      onChange={this.handleInputChange}
                      name="job.jobSpec.itemName"
                      placeholder="Name"
                      className="c-input-wide__input"
                    />
                  </div>
                ) : null}
                {!this.state.job?.jobSpec?.specVersion ||
                  this.state.job?.jobSpec?.specVersion == 1 ? (
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">Description:</label>
                    <textarea
                      rows="2"
                      type="text"
                      value={this.state.job?.jobSpec?.itemDescription}
                      onChange={this.handleInputChange}
                      name="job.jobSpec.itemDescription"
                      placeholder="Description"
                      className="c-input-wide__input"
                    />
                  </div>
                ) : null}
                {!this.state.job?.jobSpec?.specVersion ||
                  this.state.job?.jobSpec?.specVersion == 1 ? (
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">
                      Additional Info:
                    </label>
                    <textarea
                      rows="2"
                      type="text"
                      value={this.state.job?.jobSpec?.additionalInfo}
                      onChange={this.handleInputChange}
                      name="job.jobSpec.additionalInfo"
                      placeholder="Additional Info"
                      className="c-input-wide__input"
                    />
                  </div>
                ) : null}

                {/*// LEGACY */}

                <div className="c-input-wide d-flex">
                  <label className="c-input-wide__label">
                    Origin Address:
                    {this.state.loaders.originAddress ? (
                      <div className="c-input-wide__loader" />
                    ) : null}
                    {/*<button onClick={(e) => this.findAddress(e,'job?.destinationAddress')}><b>?</b></button>*/}
                  </label>
                  <input
                    type="text"
                    value={this.state.job?.originAddress?.formattedAddress}
                    onChange={(e) => {
                      this.handleInputChange(e, () => {
                        this.addressAutoComplete(e, "originAddress");
                      });
                    }}
                    onFocus={(e) => {
                      this.addressAutoComplete(e, "originAddress");
                    }}
                    onBlur={(e) => {
                      this.handleInputBlur(e, () => {
                        this.findAddress(e, "originAddress");
                      });
                    }}
                    list="job.originAddress.formattedAddress.autocomplete"
                    id="job.originAddress.formattedAddress"
                    name="job.originAddress.formattedAddress"
                    placeholder="Origin Address"
                    className="c-input-wide__input"
                    autoComplete="off"
                  />
                  {/*<textarea type="text" value={this.state.job.originAddress.formattedAddress} onFocus={(e) => { document.getElementById("job.originAddress.formattedAddress").focus() }} className="c-input-wide__input" autoComplete='off' />*/}
                  <datalist id="job.originAddress.formattedAddress.autocomplete">
                    {_.size(this.state.addressSuggestions?.originAddress) > 0
                      ? Object.keys(
                        this.state.addressSuggestions?.originAddress
                      ).map((key) => {
                        return this.renderMyAddresses(key, "originAddress");
                      })
                      : null}
                  </datalist>
                </div>
                {!this.state.job?.jobSpec?.specVersion ||
                  this.state.job?.jobSpec?.serviceType != "dispose" ? (
                  <div className="c-input-wide d-flex">
                    <label className="c-input-wide__label">
                      Destination Address:

                      {/*<button onClick={(e) => this.findAddress(e,'job?.destinationAddress')}><b>?</b></button>*/}
                    </label>
                    {this.state.loaders.destinationAddress ? (
                      <div className="c-input-wide__loader" />
                    ) : null}
                    <input
                      type="text"
                      value={
                        this.state.job?.destinationAddress?.formattedAddress
                      }
                      onChange={(e) => {
                        this.handleInputChange(e, () => {
                          this.addressAutoComplete(e, "destinationAddress");
                        });
                      }}
                      onFocus={(e) => {
                        this.addressAutoComplete(e, "destinationAddress");
                      }}
                      onBlur={(e) => {
                        this.handleInputBlur(e, () => {
                          this.findAddress(e, "destinationAddress");
                        });
                      }}
                      list="job.destinationAddress.formattedAddress.autocomplete"
                      id="job.destinationAddress.formattedAddress"
                      name="job.destinationAddress.formattedAddress"
                      placeholder="Destination Address"
                      className="c-input-wide__input"
                      autoComplete="off"
                    />
                    <datalist id="job.destinationAddress.formattedAddress.autocomplete">
                      {_.size(
                        this.state.addressSuggestions?.destinationAddress
                      ) > 0
                        ? Object.keys(
                          this.state.addressSuggestions?.destinationAddress
                        ).map((key) => {
                          return this.renderMyAddresses(
                            key,
                            "destinationAddress"
                          );
                        })
                        : null}
                    </datalist>
                  </div>
                ) : null}
                {!this.state.job?.jobSpec?.specVersion ||
                  this.state.job?.jobSpec?.serviceType != "dispose" ? (
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">Distance:</label>
                    <span className="c-input-wide__copy">
                      <b>
                        {this.state.job.originDestinationDistance.theDistance !=
                          ""
                          ? this.state.job.originDestinationDistance.theDistance
                          : "N/A"}
                      </b>
                      {this.state.job.originDestinationDistance.theDuration !=
                        ""
                        ? " (" +
                        this.state.job.originDestinationDistance.theDuration +
                        ")"
                        : ""}
                    </span>
                  </div>
                ) : null}

                {this.state.job?.jobSpec?.specVersion ? (
                  <div className="c-input-wide">
                    <div className="c-input-wide__label">Date Type:</div>
                    <select
                      className="c-input-wide__input"
                      value={this.state.job?.jobSpec?.dateAndTime?.type}
                      onChange={this.handleInputChange}
                      name="job.jobSpec.dateAndTime.type"
                    >
                      <option value="">Please select</option>
                      <option value="today">
                        {bookV2DateAndTimeToday_heading}
                      </option>
                      <option value="tomorrow">
                        {bookV2DateAndTimeTomorrow_heading}
                      </option>
                      <option value="thisweek">
                        {bookV2DateAndTimeThisweek_heading}
                      </option>
                      <option value="specificdate">
                        {bookV2DateAndTimeSpecificdate_heading}
                      </option>
                      <option value="notsure">
                        {bookV2DateAndTimeNotsure_heading}
                      </option>
                    </select>
                  </div>
                ) : null}

                {this.state.job?.jobSpec?.specVersion &&
                  this.state.job?.jobSpec?.dateAndTime?.type != "notsure" ? (
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">
                      Date
                      {this.state.job?.jobSpec?.dateAndTime?.type == "thisweek"
                        ? " (W/C)"
                        : null}
                      :
                    </label>
                    <input
                      name="date"
                      type="date"
                      className="c-input-wide__input"
                      value={this.state.date}
                      onChange={this.handleInputChange}
                      onBlur={(e) => {
                        this.setTimeDate(
                          e,
                          "job.jobSpec.dateAndTime.standardDate"
                        );
                      }}
                    />
                  </div>
                ) : null}

                {this.state.job?.jobSpec?.specVersion &&
                  this.state.job?.jobSpec?.dateAndTime?.type == "thisweek" ? (
                  <div className="c-input-wide">
                    <div className="c-input-wide__label">Days:</div>
                    <span className="c-input-wide__copy-normal">
                      <div className="c-days-select c-days-select__wrapper">
                        {Object.keys([
                          "Monday",
                          "Tuesday",
                          "Wednesday",
                          "Thursday",
                          "Friday",
                          "Saturday",
                          "Sunday",
                        ]).map(this.renderDays)}
                      </div>
                    </span>
                  </div>
                ) : null}

                {this.state.job?.jobSpec?.specVersion &&
                  (this.state.job?.jobSpec?.dateAndTime?.type == "today" ||
                    this.state.job?.jobSpec?.dateAndTime?.type == "tomorrow" ||
                    this.state.job?.jobSpec.dateAndTime?.type == "thisweek") ? (
                  <div className="c-input-wide ">
                    <div className="c-input-wide__label">Time Range:</div>
                    <span className="c-input-wide__copy-normal range-slider-main">
                      <div>
                        <ReactSlider
                          className="horizontal-slider"
                          thumbClassName="thumb"
                          trackClassName="track"
                          defaultValue={[
                            this.state.job?.jobSpec?.dateAndTime?.timeRange
                              ?.min ?? this.state?.sliderRange?.min,
                            this.state.job?.jobSpec?.dateAndTime?.timeRange
                              ?.max,
                          ]}
                          ariaLabel={["Lower thumb", "Upper thumb"]}
                          ariaValuetext={(state) =>
                            `Thumb value ${state.valueNow}`
                          }
                          renderThumb={(props, state) => (
                            <div {...props}>
                              <span>{state.valueNow + ":00"}</span>
                            </div>
                          )}
                          pearling
                          min={this.state?.sliderRange?.min}
                          max={this.state?.sliderRange?.max}
                          minDistance={1}
                          onChange={(value) => this.handleSliderChange(value)}
                        />
                        {/* <InputRange
												maxValue={this.state.sliderRange.max}
												minValue={this.state.sliderRange.min}
												formatLabel={value => value + ':00'}
												onChange={(value) => this.handleSliderChange(value)}
												onChangeComplete={(value) => this.handleSliderComplete(value)}
												value={this.state.job?.jobSpec?.dateAndTime?.timeRange} /> */}
                      </div>
                    </span>
                  </div>
                ) : null}

                {this.state.job?.jobSpec?.specVersion &&
                  this.state.job?.jobSpec?.dateAndTime?.type == "specificdate" ? (
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">Time:</label>
                    <input
                      name="time"
                      type="time"
                      className="c-input-wide__input"
                      value={this.state.time}
                      onChange={this.handleInputChange}
                      onBlur={(e) => {
                        this.setTimeDate(
                          e,
                          "job.jobSpec.dateAndTime.standardDate"
                        );
                      }}
                    />
                  </div>
                ) : null}

                {this.state.job?.jobSpec?.specVersion ? (
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">
                      Expected Price:
                    </label>
                    <input
                      name="job.jobSpec.expectedPrice"
                      type="number"
                      className="c-input-wide__input"
                      value={this.state.job?.jobSpec?.expectedPrice}
                      onChange={this.handleInputChange}
                    />
                  </div>
                ) : null}

                {/*// LEGACY : V1 job spec */}

                {!this.state.job?.jobSpec?.specVersion ||
                  this.state.job?.jobSpec?.specVersion == 1 ? (
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">Date:</label>
                    {/*<input type="text" value={this.state.job?.jobSpec?.date} onChange={this.handleInputChange} name="job?.jobSpec?.date" placeholder="Date" className="c-input-wide__input"/>*/}
                    <input
                      name="displayDate"
                      type="date"
                      className="c-input-wide__input"
                      value={this.state.displayDate}
                      onChange={this.handleInputChange}
                      onBlur={(e) => {
                        this.setTimeDateV1(e, false, e.target.value);
                      }}
                      onClick={(e) => {
                        (!this.state.displayDate ||
                          this.state.displayDate == "") &&
                          (!this.state.job?.jobSpec?.time ||
                            this.state.job?.jobSpec?.time == "")
                          ? this.setTimeDateV1(e, "tomorrow", "tomorrow")
                          : null;
                      }}
                    />
                  </div>
                ) : null}

                {/*// LEGACY */}
                {/*// LEGACY : V1 job spec */}

                {!this.state.job?.jobSpec?.specVersion ||
                  this.state.job?.jobSpec?.specVersion == 1 ? (
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">Time:</label>
                    {/*<input type="text" value={this.state.job?.jobSpec?.time} onChange={this.handleInputChange} name="job?.jobSpec?.time" placeholder="Time" className="c-input-wide__input"/>*/}
                    <input
                      name="job.jobSpec.time"
                      type="time"
                      className="c-input-wide__input"
                      value={this.state.job?.jobSpec?.time}
                      onChange={this.handleInputChange}
                      onBlur={(e) => {
                        this.setTimeDateV1(e, e.target.value, false);
                      }}
                      onClick={(e) => {
                        (!this.state.displayDate ||
                          this.state.displayDate == "") &&
                          (!this.state.job?.jobSpec?.time ||
                            this.state.job?.jobSpec?.time == "")
                          ? this.setTimeDateV1(e, "tomorrow", "tomorrow")
                          : null;
                      }}
                    />
                  </div>
                ) : null}

                {/*// LEGACY */}

                {/*// LEGACY : V1 job spec */}

                {!this.state.job?.jobSpec?.specVersion ||
                  this.state.job?.jobSpec?.specVersion == 1 ? (
                  <div className="c-input-wide">
                    <div className="c-input-wide__label">Weight Indication</div>
                    <select
                      className="c-input-wide__input"
                      value={this.state.job?.jobSpec?.itemWeight}
                      onChange={this.handleInputChange}
                      name="job.jobSpec.itemWeight"
                    >
                      <option value="">Please select</option>
                      <option value="light">Light</option>
                      <option value="moderate">Moderate</option>
                      <option value="heavy">Heavy</option>
                    </select>
                  </div>
                ) : null}

                {/*// LEGACY */}

                <div className="c-input-wide">
                  <label className="c-input-wide__label">
                    Agreed Quote Price :
                  </label>
                  <span className="c-input-wide__copy">
                    <b>
                      {this.state.agreedQuotePrice === ""
                        ? "-"
                        : "£" + this.state.agreedQuotePrice}
                    </b>
                  </span>
                </div>
                <div className="c-input-wide">
                  <label className="c-input-wide__label">
                    Commission (
                    {this.state.job.commission * 100 || defaultCommission * 100}
                    %):
                  </label>
                  <span className="c-input-wide__copy">
                    <b>
                      {this.state.agreedCommission === ""
                        ? "-"
                        : "£" + this.state.agreedCommission}
                    </b>
                  </span>
                </div>
                <div className="c-input-wide">
                  <label className="c-input-wide__label">
                    Driver Quoted Price :
                  </label>
                  <span className="c-input-wide__copy">
                    <b>
                      {this.state.agreedQuotePrice === ""
                        ? "-"
                        : "£" +
                        (
                          this.state.agreedQuotePrice -
                          this.state.agreedCommission
                        ).toFixed(2)}
                    </b>
                  </span>
                </div>
                {this.state.job.status == "complete" &&
                  this.state.job?.jobSpec?.specVersion &&
                  this.state.job?.jobSpec?.specVersion > 1 ? (
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">
                      API/Marketing Description:
                    </label>
                    <textarea
                      rows="2"
                      type="text"
                      value={this.state.job.marketingDescription}
                      onChange={this.handleInputChange}
                      name="job.marketingDescription"
                      placeholder="Description"
                      className="c-input-wide__input"
                    />
                  </div>
                ) : null}
              </div>

              <div className="l-col-33">
                <AuditNotes
                  ref="auditNotes"
                  notes={this.state.job.notes}
                  updateNotes={this.updateNotes}
                />
              </div>
            </div>
          </form>

          {this.state.job?.jobSpec?.images?.length != 0 ? (
            <div className="c-hr"></div>
          ) : (
            ""
          )}

          <div className="l-row">
            {Object.keys(this.state.job?.jobSpec?.images).map(
              this.renderImages
            )}
          </div>

          <div className="l-row l-row">
            <div className="l-col-100">
              <h2 className="u-margin-bottom-0">
                Quotes{" "}
                {this.state.mavPool ? (
                  <span className="u-color-teal u-font-size-16 u-font-regular">
                    (<b>{this.state.mavPool.quoted}</b> /{" "}
                    <b>{this.state.mavPool.total}</b>{" "}
                    {this.state.mavPool.type == "local" ? "Local" : "Total"}{" "}
                    MAVs)
                  </span>
                ) : null}
              </h2>
            </div>
          </div>

          <div className="c-hr"></div>

          {this.state.selectedMav && this.state.selectedMav != "" ? (
            <div className="l-row">
              <div className="l-col-33"></div>
              <div className="l-col-66">{this.renderSelectedQuote()}</div>
            </div>
          ) : null}

          <div className="l-row">
            <div className="l-col-33"></div>
            <div className="l-col-66">
              {this.state.job.quotes == "" || this.state.job.quotes.length == 0
                ? "No quotes for this job"
                : Object.keys(this.state.job.quotes).map(this.renderQuotes)}
            </div>
          </div>

          <MavSelectComponent
            ref="mavSelectComponent"
            selectMav={this.selectMav}
            currentMav={this.state.selectedMav}
            userDetails={this.props.userDetails}
          />
        </div>
      );
    }
  }
}

Job.contextTypes = {
  router: PropTypes.object,
};

Job.propTypes = {
  userDetails: PropTypes.object.isRequired,
  jobId: PropTypes.string.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default withNavigate(Job);
